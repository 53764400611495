import React from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  auditAssigneeColumn,
  auditBonusImpactColumn,
  auditCreationDateColumn,
  auditDepartmentNameColumn,
  auditDueDateColumn,
  auditIssueLinkColumn,
  auditLatenessColumn,
  auditParentLinkColumn,
  auditSeverityColumn,
  auditSummaryColumn,
  auditTeamNameColumn,
  auditTypeColumn,
} from '@src/constants/columns/audit'
import { AuditInterface, AuditStatuses, AuditTypes } from '@src/interfaces/audit'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { FindingsTab } from '@src/features/Findings/FindingsTab'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'

const row: RowInterface<AuditInterface> = {
  cells: [
    {
      ...auditDepartmentNameColumn,
      width: 140,
    },
    {
      ...auditTypeColumn,
      width: 100,
    },
    {
      ...auditTeamNameColumn,
      width: 200,
    },
    {
      ...auditIssueLinkColumn,
      width: 100,
    },
    {
      ...auditSummaryColumn,
      width: 200,
    },
    {
      ...auditAssigneeColumn,
      width: 140,
    },
    {
      ...auditParentLinkColumn,
      width: 120,
    },
    {
      ...auditDueDateColumn,
      width: 140,
    },
    {
      ...auditCreationDateColumn,
      width: 140,
    },
    {
      ...auditSeverityColumn,
      width: 140,
    },
    {
      ...auditLatenessColumn,
      width: 140,
    },
    {
      ...auditBonusImpactColumn,
      width: 140,
    },
  ],
}

const initialFilterBy = [
  {
    filters: [
      { name: `critical`, id: 'critical' },
      { name: `high`, id: 'high' },
    ],
    columnName: 'severity_v2',
    nonResettable: true,
  },
  {
    filters: [
      { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
      { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
    ],
    columnName: 'status',
    nonResettable: true,
  },
  {
    filters: [{ id: AuditTypes.AUDIT, name: AuditTypes.AUDIT }],
    columnName: 'category',
    nonResettable: true,
  },
]

const initialSortBy = [
  {
    sortBy: 'severity_v2',
    direction: SORT_DIRECTION.ASC,
  },
  {
    sortBy: 'team_name',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'due_date',
    direction: SORT_DIRECTION.DESC,
  },
]

export const Findings = () => {
  return (
    <FindingsTab
      entity={EntityTypes.company}
      initialFilter={initialFilterBy}
      initialSort={initialSortBy}
      row={row}
      navigation={<CompanyNavigation />}
    />
  )
}

export default Findings
