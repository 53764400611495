import React from 'react'
import { Carousel, Tile, Text, LineClamp } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { DashboardTileItem } from '@src/interfaces/dashboard'
import { ANNOUNCEMENT_WIDTH } from './common'

export const DashboardTile = (tile: DashboardTileItem) => {
  return (
    <Carousel.Item width={ANNOUNCEMENT_WIDTH}>
      <Tile
        image={tile.image}
        variant="small"
        onClick={() => {
          if (tile.action_url) {
            navigateTo(tile.action_url)
          }
        }}
      >
        <Tile.Title>{tile.title}</Tile.Title>
        <Tile.Description>
          <Text fontSize="small" lineHeight={1.1}>
            <LineClamp max={2}>{tile.description}</LineClamp>
          </Text>
        </Tile.Description>
      </Tile>
    </Carousel.Item>
  )
}
