import React from 'react'

import { ColorTag, ColorTagVariant } from '@components/ColorTag/ColorTag'
import { Statuses } from '@src/interfaces'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { formatSnakeCase } from '@src/utils/string'
import { IdStatuses } from '@src/interfaces/employees'

export const getDefaultStatusVariant = (status: Statuses): ColorTagVariant => {
  switch (status) {
    case Statuses.open:
    case Statuses.active:
      return 'success'

    case Statuses.preapproved:
    case Statuses.funcapproved:
    case Statuses.extended:
    case Statuses.applied:
    case Statuses.pending:
    case Statuses.pending_approval:
    case Statuses.pending_reapproval:
    case Statuses.pending_completion:
    case Statuses.planned:
    case Statuses.requires_changes:
    case Statuses.at_risk:
      return 'warning'

    case Statuses.revoked:
    case Statuses.cancelled:
    case Statuses.canceled:
    case Statuses.terminated:
    case Statuses.delayed:
      return 'danger'

    case Statuses.archived:
    case Statuses.expired:
    case Statuses.inactive:
    case Statuses.draft:
    case Statuses.future:
    case Statuses.opened:
    case Statuses.unassigned:
    case Statuses.closed:
    case Statuses.approved:
    case Statuses.completed:
    case Statuses.rejected:
      return 'neutral'

    default:
      return 'default'
  }
}

export const getDefaultIdStatusVariant = (
  status: IdStatuses | undefined,
): ColorTagVariant => {
  switch (status) {
    case IdStatuses.hired:
    case IdStatuses.active:
    case IdStatuses.completed:
      return 'success'

    case IdStatuses.pending:
    case IdStatuses.onboarding:
      return 'warning'

    case IdStatuses.not_hired:
    case IdStatuses.refused:
    case IdStatuses.failed:
    case IdStatuses.terminated:
    case IdStatuses.cancelled:
      return 'danger'

    case IdStatuses.inactive:
      return 'neutral'

    default:
      return 'default'
  }
}

export const getDefaultApprovalStatusVariant = (
  status: ApprovalStatuses,
): ColorTagVariant => {
  switch (status) {
    case ApprovalStatuses.Pending:
      return 'warning'

    default:
      return 'neutral'
  }
}

interface StatusCellProps {
  status: string
  variant: ColorTagVariant
}

export const StatusCell = ({ status, variant }: StatusCellProps) => {
  return <ColorTag variant={variant}>{formatSnakeCase(status)}</ColorTag>
}
