import React from 'react'

import { HubAppType } from '@src/interfaces/hub'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { OnboardingCheckpointAppCategory } from '@src/interfaces/onboardingChecklistV2'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import HubApp from './HubApp'

interface HubAppWithOnboardingScreenProps {
  app: HubAppType
  category: OnboardingCheckpointAppCategory
}

export const HubAppWithOnboardingScreen = ({
  category,
  app,
}: HubAppWithOnboardingScreenProps) => {
  return (
    <OnboardingAppScreen
      category={category}
      contentAbove={
        <PageHeader pt="s-0" title={app.title} backUrl={ROUTES.FEATURES.APPS} />
      }
    >
      <HubApp app={app} />
    </OnboardingAppScreen>
  )
}
