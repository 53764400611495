import React, { useState } from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import {
  locationCountryColumn,
  locationCreatedByColumn,
  locationCreatedDateColumn,
  locationGenericNameColumn,
  locationPeoplePartnerColumn,
  locationPeopleSpecialistsColumn,
  locationStatusColumn,
  locationTypeColumn,
  locationLabelColumn,
} from '@src/constants/columns/location'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { LocationInterface } from '@src/interfaces/locations'
import { locationsRequests } from '@src/api/locations'
import { TableNames } from '@src/constants/table'
import { useGetLocationSettings } from '@src/api/settings'
import { LocationSidebarForm } from '@src/pages/Forms/LocationForm/LocationSidebarForm'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const RowNotifications: RowInterface<LocationInterface> = {
  cells: [
    {
      ...locationGenericNameColumn,
      width: 120,
    },
    {
      ...locationCountryColumn,
      width: 100,
    },
    {
      ...locationTypeColumn,
      width: 100,
    },
    {
      ...locationLabelColumn,
      width: 150,
    },
    {
      ...locationPeoplePartnerColumn,
      width: 200,
    },
    {
      ...locationPeopleSpecialistsColumn,
      width: 200,
    },
    {
      ...locationCreatedDateColumn,
      width: 100,
    },
    {
      ...locationCreatedByColumn,
      width: 200,
    },
    {
      ...locationStatusColumn,
      width: 150,
    },
  ],
}

const LocationTable = () => {
  const { data: settings } = useGetLocationSettings()
  const [addSidebarOpen, setAddSidebarOpen] = useState(false)
  const [addSidebarRenderKey, setAddSidebarRenderKey] = useState(0)
  const [locationId, setLocationId] = useState<number | undefined>()

  const sortBy = [
    {
      sortBy: 'location_name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const filterBy = [
    {
      columnName: 'status',
      filters: [{ id: 'active', name: 'active' }],
      nonResettable: true,
    },
  ]
  const table = useTable<LocationInterface>(locationsRequests, filterBy, sortBy)
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddLocations)

  return (
    <>
      <Table.Widget>
        {canAdd && (
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              <PrimaryAction
                onClick={() => {
                  setAddSidebarOpen(true)
                  setAddSidebarRenderKey(prev => prev + 1)
                  setLocationId(undefined)
                }}
                useIcon="Plus"
              >
                Add Location
              </PrimaryAction>
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
        )}
        <Table.Widget.Table>
          <AdjustableTable<LocationInterface>
            dataType="Location"
            hiddenCells={{
              [locationPeoplePartnerColumn.idPoint]: !settings?.enable_location_hr_fields,
              [locationPeopleSpecialistsColumn.idPoint]:
                !settings?.enable_location_hr_fields,
              [locationLabelColumn.idPoint]: !settings?.location_labels?.length,
            }}
            name={TableNames.Locations}
            emptyState={<EmptyTableRaw title="Locations will appear here" />}
            onRowClick={data => {
              setAddSidebarOpen(true)
              setAddSidebarRenderKey(prev => prev + 1)
              setLocationId(data.id)
            }}
            row={RowNotifications}
            useWindowScroll
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>

      <LocationSidebarForm
        isOpen={addSidebarOpen}
        key={addSidebarRenderKey}
        locationId={locationId}
        onAfterSave={() => {
          setAddSidebarOpen(false)
          setLocationId(undefined)
          table.refresh()
          table.refreshStats()
        }}
        onClose={() => {
          setAddSidebarOpen(false)
          setLocationId(undefined)
        }}
      />
    </>
  )
}

export default LocationTable
