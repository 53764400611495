import React, { useState } from 'react'
import { connect } from 'lape'
import {
  VStack,
  Widget,
  Text,
  HStack,
  Spacer,
  TextButton,
  Token,
  Flex,
  Box,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import {
  actionOptions,
  announcementBackgroundIcons,
  commercialAnnouncementBackgroundIcons,
} from '@src/pages/Forms/NotificationTemplate/common'
import {
  NotificationAction,
  NotificationTemplateInterface,
} from '@src/interfaces/notificationTemplate'
import ColorSelect from '@src/pages/Forms/NotificationTemplate/components/ColorSelect/ColorSelect'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeEditor from '@components/Inputs/LapeFields/LapeEditor'
import { AnnouncementTile } from '@src/pages/Home/HomeSubTabs/Dashboard/components/Announcements/AnnouncementTile'
import { selectUser } from '@src/store/auth/selectors'
import { getAvatarUrl } from '@src/utils/employees'
import { ANNOUNCEMENT_WIDTH } from '@src/pages/Home/HomeSubTabs/Dashboard/components/Announcements/common'
import SideBar from '@src/components/SideBar/SideBar'
import { formatDate } from '@src/utils/format'
import useIsCommercial from '@src/hooks/useIsCommercial'

const AnnouncementContent = () => {
  const [backgroundIconSidebarOpen, setBackgroundIconSidebarOpen] = useState(false)

  const { values } = useLapeContext<NotificationTemplateInterface>()
  const user = useSelector(selectUser)
  const isCommercial = useIsCommercial()

  const backgroundIcons = isCommercial
    ? commercialAnnouncementBackgroundIcons
    : ([
        ...announcementBackgroundIcons,
        ...commercialAnnouncementBackgroundIcons,
      ] as const)
  if (!values.announcement_action) {
    values.announcement_action = NotificationAction.url
  }
  if (values.announcement_show_icon == null) {
    values.announcement_show_icon = false
  }
  if (values.announcement_pinned == null) {
    values.announcement_pinned = false
  }

  const announcementTileProps = {
    title: values.announcement_title || '',
    description: values.announcement_subtitle,
    onClick: () => {},
    color: values.announcement_color || Token.color.blue,
    backgroundIcon: values.announcement_background_icon,
    icon: values.owner?.avatar
      ? getAvatarUrl(values.owner.avatar)
      : user.avatar
      ? getAvatarUrl(user.avatar)
      : undefined,
    senderName: values.owner?.full_name || user.full_name,
    pinned: values.announcement_pinned,
    dismiss: () => {},
    showIcon: values.announcement_show_icon,
    date: values.created_date_time || formatDate(new Date()),
  }

  return (
    <>
      <LapeNewInput
        name="announcement_title"
        label="Title"
        message="Make it short and engaging"
        required
      />
      <LapeNewInput name="announcement_subtitle" label="Subtitle" required />
      <RadioSelectInput
        label="Action when clicking on announcement"
        message="Define what should happen when user clicks on announcement"
        options={actionOptions}
        onChange={val => {
          values.announcement_action = val?.id
        }}
        value={
          actionOptions.find(option => option.value.id === values.announcement_action)
            ?.value
        }
        searchable={false}
        required
      />
      {values.announcement_action === NotificationAction.url ? (
        <LapeNewInput
          name="announcement_action_url"
          label="Url"
          message="Define URL where user should navigate after clicking on announcement"
          required
        />
      ) : (
        <LapeEditor name="template_text" placeholder="Description" />
      )}
      <ColorSelect />
      <LapeNewSwitch
        itemTypeProps={{
          title: 'Show avatar',
          description: 'Show owner’s avatar in the announcement tile',
        }}
        name="announcement_show_icon"
      />

      <Widget p="s-16">
        <VStack space="s-16">
          <HStack space="s-8">
            <Text color={Token.color.greyTone50}>
              Select announcement background image (optional)
            </Text>
            <Spacer />

            {values.announcement_background_icon ? (
              <TextButton
                onClick={() => {
                  values.announcement_background_icon = null
                }}
                color={Token.color.danger}
              >
                Remove
              </TextButton>
            ) : null}
            <TextButton
              onClick={() => {
                setBackgroundIconSidebarOpen(true)
              }}
            >
              Select
            </TextButton>
          </HStack>

          <Flex alignItems="center" justifyContent="center">
            <Box width={ANNOUNCEMENT_WIDTH}>
              <AnnouncementTile
                {...announcementTileProps}
                key={values.announcement_background_icon}
              />
            </Box>
          </Flex>
        </VStack>
      </Widget>

      <LapeNewSwitch
        itemTypeProps={{
          title: 'Pin announcement to employees’ dashboards',
          description:
            'For special notices that will be kept visible for employees during given time frame.',
        }}
        name="announcement_pinned"
      />
      {values.announcement_pinned ? (
        <LapeDatePickerInput
          clearable={false}
          name="announcement_pinned_to"
          label="When notification should disappear from dashboards?"
          disabledDays={{ before: new Date() }}
          required
        />
      ) : null}

      <SideBar
        useLayout
        onClose={() => setBackgroundIconSidebarOpen(false)}
        title="Select background image"
        isOpen={backgroundIconSidebarOpen}
      >
        <VStack space="s-8">
          {backgroundIcons.map(icon => {
            return (
              <Box width={ANNOUNCEMENT_WIDTH} key={icon}>
                <AnnouncementTile
                  {...announcementTileProps}
                  backgroundIcon={icon}
                  onClick={() => {
                    values.announcement_background_icon = icon
                  }}
                />
              </Box>
            )
          })}
        </VStack>
      </SideBar>
    </>
  )
}

export default connect(AnnouncementContent)
