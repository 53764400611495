import React from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOrganisationUnitsColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
  analyticsDashboardRelatedGoalsColumn,
  analyticsDashboardStatusColumn,
} from '@src/constants/columns/analyticsDashboard'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { RowInterface } from '@src/interfaces/data'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { useGetCompany } from '@src/api/company'
import Loader from '@components/CommonSC/Loader'
import { useGetAnalyticsStats } from '@src/api/analyticsDashboards'
import { analyticsConfig } from '@src/pages/Performance/common'
import { StatNavigation } from '@components/StatNavigation/StatNavigation'

const initialFilterBy = [
  {
    filters: [{ id: 'true', name: 'true' }],
    columnName: 'company_related',
    nonResettable: true,
  },
]

const row: RowInterface<GenericAnalyticsDashboardInterface> = {
  cells: [
    {
      ...analyticsDashboardNameColumn,
      width: 150,
    },
    {
      ...analyticsDashboardOrganisationUnitsColumn,
      width: 100,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: 50,
    },
    {
      ...analyticsDashboardRelatedGoalsColumn,
      width: 100,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 30,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 30,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 100,
    },

    {
      ...analyticsDashboardStatusColumn,
      width: 80,
    },
  ],
}

interface DashboardsProps {
  useStatsNavigation?: boolean
}

export const Dashboards = ({ useStatsNavigation = true }: DashboardsProps) => {
  const { data, isLoading } = useGetCompany()

  if (isLoading) {
    return <Loader />
  }

  return (
    <AnalyticsDashboardsTab
      data={data}
      entity={EntityTypes.company}
      initialFilter={initialFilterBy}
      row={row}
      navigation={
        useStatsNavigation ? (
          <StatNavigation api={useGetAnalyticsStats} config={analyticsConfig} />
        ) : undefined
      }
    />
  )
}

export const DashboardsWithNav = () => {
  return <Dashboards useStatsNavigation />
}

export const DashboardsWithoutNav = () => {
  return <Dashboards useStatsNavigation={false} />
}
