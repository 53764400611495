import React, { useMemo } from 'react'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import {
  NotificationDemoCategoryType,
  NotificationsInterface,
} from '@src/interfaces/notifications'
import {
  notificationDescription,
  notificationsActionColumn,
} from '@src/constants/columns/notifications'
import GenericNotificationTable from './GenericNotificationTable'
import { notificationsRequests } from '@src/api/notifications'

const Demo = ({
  filterBy,
  category,
  ...props
}: TabComponentProps & { category: NotificationDemoCategoryType }) => {
  const ROW: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(props.employeeId),
        width: 600,
      },
      {
        ...notificationsActionColumn,
        width: 200,
      },
    ],
  }

  const filters = useMemo(() => {
    return [
      ...(filterBy || []),
      {
        columnName: 'category',
        filters: [{ id: category, name: category }],
        nonResettable: true,
      },
    ]
  }, [filterBy, category])

  return (
    <GenericNotificationTable
      {...props}
      request={notificationsRequests()}
      rows={ROW}
      filterBy={filters}
    />
  )
}

export const HR = (props: TabComponentProps) => {
  return <Demo {...props} category="demo_hr" />
}

export const Performance = (props: TabComponentProps) => {
  return <Demo {...props} category="demo_performance" />
}

export const Recruitment = (props: TabComponentProps) => {
  return <Demo {...props} category="demo_recruitment" />
}

export const Platform = (props: TabComponentProps) => {
  return <Demo {...props} category="demo_platform" />
}
