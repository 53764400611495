import React, { useState, useMemo, useEffect, useRef } from 'react'
import { useGetNotificationStats } from '@src/api/notifications'
import { StatFilter, StatFilters } from '@src/components/StatFilters/StatFilters'
import {
  commonDashboardSubtabs,
  NotificationsContextProvider,
} from '@src/apps/General/Todo/common'
import Table from '@src/components/TableV2/Table'
import { useTableReturnType } from '@components/TableV2/hooks'
import {
  NotificationsInterface,
  NotificationsStatisticsInterface,
} from '@src/interfaces/notifications'
import AllNotifications from '@src/pages/Forms/Notifications/AllNotifications'

type DashboardSubtabComponent = typeof commonDashboardSubtabs[number]['component']

const todoStatsPaths = commonDashboardSubtabs.map(
  ({ statPath, title, component, url }) => ({
    statPath,
    title,
    component,
    url,
  }),
)
const primaryTodoTab = todoStatsPaths[0]
const DEMO_HR_CATEGORY = 'demo_hr'

export const DashboardToDo = () => {
  const [selectedFilter, setSelectedFilter] = useState<string>()

  const tableRef = useRef<useTableReturnType<
    NotificationsInterface,
    NotificationsStatisticsInterface
  > | null>(null)

  const selectEntities = (selection: string) => {
    setSelectedFilter(selection)

    tableRef.current?.onFilterChange({
      filters: selection === 'total' ? [] : [{ id: selection, name: selection }],
      columnName: 'category',
    })
  }

  const {
    data: stats,
    isLoading: isStatsLoading,
    refetch: refetchStats,
  } = useGetNotificationStats()

  const statFilters: (StatFilter & {
    component: DashboardSubtabComponent
    url: string
  })[] = useMemo(() => {
    if (isStatsLoading) {
      return [
        {
          id: primaryTodoTab.statPath,
          title: primaryTodoTab.title,
          value: null,
          loading: true,
          component: primaryTodoTab.component,
          url: primaryTodoTab.url,
        },
      ]
    }

    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const filteredTodoPaths = todoStatsPaths.filter(tab => Boolean(stats?.[tab.statPath]))

    if (!filteredTodoPaths.length) {
      return [
        {
          id: primaryTodoTab.statPath,
          title: primaryTodoTab.title,
          value: 0,
          loading: false,
          component: primaryTodoTab.component,
          url: primaryTodoTab.url,
        },
      ]
    }

    return filteredTodoPaths.map(tab => ({
      id: tab.statPath,
      title: tab.title,
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      value: stats?.[tab.statPath],
      component: tab.component,
      url: tab.url,
    }))
  }, [stats, isStatsLoading])

  useEffect(() => {
    if (!isStatsLoading) {
      const isDemo = statFilters.some(f => f.id === DEMO_HR_CATEGORY)
      if (isDemo) {
        selectEntities(DEMO_HR_CATEGORY)
      }
    }
  }, [isStatsLoading])

  const selectedTab = statFilters.find(f => f.id === selectedFilter)
  useEffect(() => {
    /** If the last todo item was cleared from a tab, stats refresh and that tab is gone. So we need to fallback to the primary tab */
    if (!selectedTab) {
      selectEntities(primaryTodoTab.statPath)
    }
  }, [selectedTab])

  const SelectedTabComponent = selectedTab?.component

  useEffect(() => {
    /** If the last todo item was cleared from a tab, stats refresh and that tab is gone. So we need to fallback to the primary tab */
    if (!SelectedTabComponent) {
      setSelectedFilter(primaryTodoTab.statPath)
    }
  }, [SelectedTabComponent])

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <StatFilters
          selectedFilter={selectedFilter}
          filters={statFilters}
          onClick={selectEntities}
        />
      </Table.Widget.Info>

      <Table.Widget.Table>
        <NotificationsContextProvider>
          <AllNotifications
            type="dashboard"
            tableRef={tableRef}
            refreshStats={() => refetchStats()}
          />
        </NotificationsContextProvider>
      </Table.Widget.Table>
    </Table.Widget>
  )
}
