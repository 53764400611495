import { GetRequestData, GetRequestInterface, tableRequests } from '@src/interfaces'
import {
  Dispatch,
  Reducer,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import {
  FetchDataQueryInterface,
  FilterByInterface,
  SortByInterface,
  Stats,
} from '@src/interfaces/data'
import unionBy from 'lodash/unionBy'
import set from 'lodash/set'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { getQueries, useQuery } from '@src/utils/queryParamsHooks'
import {
  filterToString,
  queryToFilter,
  queryToSort,
  sortToString,
} from '@src/utils/table'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { childrenRequests, TableNames } from '@src/constants/table'
import { AxiosError, AxiosPromise } from 'axios'
import { multiDragReorder } from '@components/SortableList/utils'
import { UpdateOrderingInterface } from '@src/interfaces/ordering'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { TableSettingsInterface } from '@src/interfaces/tableSettings'
import { LocalStorageKeys } from '@src/store/auth/types'
import uniq from 'lodash/uniq'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface FetchingInterface {
  page: number
  sortBy: SortByInterface[]
  filters: FilterByInterface[]
}

interface ColumnsInterface {
  columns?: { name: string; type: string }[]
}

const getNormalizedStats = (stats: any) => {
  const normalizedStats = { ...stats }

  if (normalizedStats.avg_done) {
    // because it's difficult to pass avg_done as a 0-1 range number on the backend ¯\_(ツ)_/¯
    normalizedStats.avg_done /= 100
  }

  return normalizedStats
}

const metadataOmitKeys: Array<keyof GetRequestData<any>> = ['results', 'count', 'pages']

export interface useTableReturnType<T, S = Stats, M = {}> {
  data: T[]
  stats?: S
  setData: Dispatch<SetStateAction<T[]>>
  count: number
  setCount: Dispatch<SetStateAction<number>>
  loading: boolean
  nextPageLoading: boolean
  metadata?: M
  onSortChange: (sort: SortByInterface) => void
  fetchSelectors: (
    fieldKey: selectorKeys,
  ) => () => Promise<{ options: OptionInterface[] }>
  resetFiltersAndSorting: (
    filterBy?: FilterByInterface[],
    sortBy?: SortByInterface[],
  ) => void
  sortBy: SortByInterface[]
  onFilterChange: (
    filter: FilterByInterface | FilterByInterface[],
    resetDefaultFilters?: boolean,
    forceResetOnFilter?: boolean,
  ) => void
  filterBy: FilterByInterface[]
  fetchNextPage: () => void
  fetchChildren: (
    parentIndexes: number[],
    id: number | string,
    extraFilters?: FilterByInterface[],
  ) => Promise<any>
  refresh: () => void
  refreshStats: () => void
  fetchError?: AxiosError
  fetchQuery: FetchDataQueryInterface
  columns: { name: string; type: string }[]
  updateRows: (selector: (row: T) => boolean, cb: (row: T) => T) => void
}

export interface UseTableOptions {
  disable?: boolean
  disableOnEmptyFilters?: boolean
  disableQuery?: boolean
  omitKeys?: string[]
  refetchOnApiChange?: boolean
  refetchOnWindowFocus?: boolean
  parentIdFilterKey?: string
}

export const useTable = <T, S = Stats, M extends Record<string, any> = {}>(
  request: tableRequests<T, S, M>,
  filterByInitial: FilterByInterface[] = [],
  sortByInitial: SortByInterface[] = [],
  options: UseTableOptions = {},
): useTableReturnType<T, S, M> => {
  const { sendAnalyticsEvent } = useAnalytics()
  options.omitKeys = options.omitKeys || []

  const { query, changeQueryParam, deleteQuery, deleteQueryParam } = useQuery(
    false,
    options.disableQuery,
  )

  const { ordering, ...filters } = query
  const filtersFromQuery = useMemo(
    () => queryToFilter(omit(filters, options.omitKeys!), filterByInitial),
    [],
  )
  const sortByFromQuery = useMemo(() => queryToSort(ordering), [])
  const [data, setData] = useState<T[]>([])
  const [stats, setStats] = useState<S>()
  const [loading, setLoading] = useState(true)
  const [nextPageLoading, setNextPageLoading] = useState(false)
  // Do not reset filters if they are in query, because creating query already reset the filters
  const [sortingNotResetOnce, setInitialSorted] = useState(query.ordering === undefined)
  const [filteringNotResetOnce, setInitialFiltered] = useState(!filtersFromQuery.length)
  const [total, setTotal] = useState(1)
  const [count, setCount] = useState(0)
  const [columns, setColumns] = useState<{ name: string; type: string }[]>([])
  const [metadata, setMetadata] = useState<M>()
  const [fetchError, setFetchError] = useState<AxiosError>()
  const [fetchQuery, setFetchQuery] = useReducer<
    Reducer<FetchingInterface, Partial<FetchingInterface>>
  >((state, newState) => ({ ...state, ...newState }), {
    page: 1,
    sortBy: query.ordering !== undefined ? sortByFromQuery : sortByInitial,
    filters:
      filtersFromQuery.length !== 0
        ? unionBy(
            filtersFromQuery,
            filterByInitial.filter(sort => sort.nonResettable),
            'columnName',
          )
        : filterByInitial,
  })

  const disableFetchingData =
    options.disable || (options.disableOnEmptyFilters && !fetchQuery.filters.length)

  const resetQuery = options.omitKeys!.length
    ? () =>
        Object.keys(filters).forEach(key => {
          if (options.omitKeys!.includes(key)) {
            return
          }
          deleteQueryParam(key)
        })
    : () => deleteQuery()

  // Reference fetchQuery data for callbacks
  const stateRef = useRef<FetchingInterface>()
  stateRef.current = fetchQuery

  const fetchStats = () => {
    if (!request.getStats) {
      return
    }

    request.getStats(fetchQuery).then(responseStats => {
      if (responseStats && responseStats.data) {
        const normalizedStats = getNormalizedStats(responseStats.data)
        setStats(normalizedStats)
      }
    })
  }

  const fetchData = () => {
    const startTime = performance.now()
    setLoading(true)

    const promises: [
      AxiosPromise<GetRequestInterface<T, ColumnsInterface>>,
      AxiosPromise<S> | undefined,
    ] = [request.getItems(fetchQuery), undefined]

    if (request.getStats) {
      promises[1] = request.getStats(fetchQuery)
    }

    Promise.all(promises)
      .then(([response, responseStats]) => {
        const newData = response.data.results || response.data
        setCount(response.data.count || 0)
        setTotal(response.data.pages && response.data.pages.total)
        setMetadata(omit(response.data, metadataOmitKeys) as M)
        setColumns(response.data.columns || [])
        setFetchError(undefined)
        if (fetchQuery.page > 1) {
          setData([...data, ...newData])
        } else {
          setData(newData)
        }
        if (responseStats && responseStats.data) {
          const normalizedStats = getNormalizedStats(responseStats.data)
          setStats(normalizedStats)
        }
        const finishTime = performance.now()
        sendAnalyticsEvent(AnalyticsEvents.table_loaded_success, {
          time: Math.round(finishTime - startTime),
        })
      })
      .catch(error => {
        setFetchError(error)
        const finishTime = performance.now()
        sendAnalyticsEvent(AnalyticsEvents.table_loaded_error, {
          errors: error,
          time: Math.round(finishTime - startTime),
        })
        throw error
      })
      .finally(() => {
        setLoading(false)
        setNextPageLoading(false)
      })
  }

  const refresh = () => {
    setLoading(true)

    const promisesData: AxiosPromise<GetRequestInterface<T, ColumnsInterface>>[] = []

    for (let i = 1; i <= fetchQuery.page; i++) {
      promisesData.push(request.getItems({ ...fetchQuery, page: i }))
    }

    Promise.all(promisesData)
      .then(responses => {
        const newData = responses.reduce<T[]>((acc, response) => {
          acc.push(...(response.data.results || response.data))
          return acc
        }, [])

        setData(newData)
        setCount(responses[0].data.count || 0)
        setMetadata(omit(responses[0].data, metadataOmitKeys) as M)
        setTotal(responses[0].data.pages && responses[0].data.pages.total)
        setColumns(responses[0].data.columns || [])
        setLoading(false)
        setFetchError(undefined)
      })
      .catch(error => {
        setFetchError(error)
        setLoading(false)
        throw error
      })

    if (request.getStats) {
      request.getStats(fetchQuery).then(response => {
        if (response && response.data) {
          const normalizedStats = getNormalizedStats(response.data)
          setStats(normalizedStats)
        }
      })
    }
  }

  const onVisibilityChangeHandler = () => {
    if (!document.hidden) {
      refresh()
    }
  }

  useEffect(() => {
    if (options.refetchOnWindowFocus) {
      document.addEventListener('visibilitychange', onVisibilityChangeHandler, false)
    }

    return () => {
      if (options.refetchOnWindowFocus) {
        document.removeEventListener('visibilitychange', onVisibilityChangeHandler, false)
      }
    }
  }, [options.refetchOnWindowFocus])

  useEffect(() => {
    if (!disableFetchingData) {
      fetchData()
    }
  }, [fetchQuery, disableFetchingData, options.refetchOnApiChange && request])

  const resetSortQuery = () => {
    changeQueryParam('ordering', '', true)
  }
  const resetFiltersQuery = () => {
    const isOrdering = getQueries()?.ordering
    resetQuery()

    // Keep sorting if it exist
    if (isOrdering && stateRef.current?.sortBy) {
      changeQueryParam('ordering', sortToString(stateRef.current?.sortBy))
    }

    const filtersToReset = filterByInitial.filter(fil => !fil.nonResettable)
    filtersToReset.forEach(filter => {
      if (!filter.disableQueryParam) {
        changeQueryParam(filter.columnName, '', true)
      }
    })
  }

  const resetFiltersAndSorting = (
    filterBy: FilterByInterface[] = [],
    sortBy: SortByInterface[] = [],
  ) => {
    resetQuery()

    const staticOrdering = unionBy(
      sortBy,
      sortByInitial.filter(sort => sort.nonResettable),
      'sortBy',
    )

    changeQueryParam('ordering', sortToString(staticOrdering), true)

    // override default resettable filters
    filterByInitial
      .filter(sort => !sort.nonResettable)
      .forEach(filter => {
        if (!filter.disableQueryParam) {
          changeQueryParam(filter.columnName, '', true)
        }
      })

    setFetchQuery({
      page: 1,
      sortBy: staticOrdering,
      filters: unionBy(
        filterBy,
        filterByInitial.filter(sort => sort.nonResettable),
        'columnName',
      ),
    })
  }

  const fetchNextPage = () => {
    if (fetchQuery.page! < total && !loading) {
      setNextPageLoading(true)
      setFetchQuery({ page: fetchQuery.page! + 1 })
    }
  }

  const fetchSelectors = (
    fieldKey: selectorKeys,
  ): (() => Promise<{ options: OptionInterface[] }>) => {
    return () =>
      new Promise(resolve => {
        if (request.getSelectors) {
          request.getSelectors(fieldKey).then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
        } else {
          resolve({ options: [] as OptionInterface[] })
        }
      })
  }

  const fetchChildren = (
    parentIndexes: number[],
    id: number | string,
    extraFilters: FilterByInterface[] = [],
  ) => {
    const pathToParentChildren = parentIndexes.flatMap(index => [index, 'children'])
    const pathToParent = pathToParentChildren.slice(0, pathToParentChildren.length - 1)
    const parent = get(data, pathToParent)
    const childrenRequest: tableRequests<T, S, M> = parent.children_type
      ? /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        childrenRequests[parent.children_type]
      : request
    const filtersForChildren = fetchQuery.filters
      .filter(fil => !fil.nonInheritable)
      .concat([
        {
          filters: [{ name: `${id}`, id }],
          columnName: options.parentIdFilterKey || 'parent__id',
        },
        ...extraFilters,
      ])
    const sortingForChildren = fetchQuery.sortBy.filter(sort => !sort.nonInheritable)
    return childrenRequest
      .getItems({
        filters: filtersForChildren,
        sortBy: sortingForChildren,
      })
      .then(response => {
        const newData = [...data]
        set(newData, pathToParentChildren, response.data.results)
        setData(newData)
        return response.data.results
      })
      .catch(error => {
        console.warn(error)
        throw error
      })
  }

  const onSortChange = (sort: SortByInterface) => {
    let newSortBy = fetchQuery.sortBy
    if (sortingNotResetOnce) {
      resetSortQuery()
      newSortBy = []
      setInitialSorted(false)
    }
    if (sort.direction) {
      if (newSortBy.find(srt => srt.sortBy === sort.sortBy)) {
        newSortBy = newSortBy.map(oldSort =>
          oldSort.sortBy === sort.sortBy ? sort : oldSort,
        )
      } else {
        newSortBy = newSortBy.concat(sort)
      }
    }
    if (!sort.direction) {
      newSortBy = newSortBy.filter(s => s.sortBy !== sort.sortBy)
    }
    changeQueryParam('ordering', sortToString(newSortBy), true)
    setFetchQuery({ page: 1, sortBy: newSortBy })
  }

  const setNewFilter = (filter: FilterByInterface, newFilters: FilterByInterface[]) => {
    let setEmpty = false
    if (filter.filters.length) {
      newFilters = unionBy([filter], newFilters, 'columnName')
    } else if (filterByInitial.some(init => init.columnName === filter.columnName)) {
      setEmpty = true
      newFilters = newFilters.map(fetchQueryFilter =>
        fetchQueryFilter.columnName === filter.columnName ? filter : fetchQueryFilter,
      )
    } else {
      newFilters = newFilters.filter(s => s.columnName !== filter.columnName)
    }

    if (!filter.disableQueryParam) {
      changeQueryParam(filter.columnName, filterToString(filter), setEmpty)
    }

    return newFilters
  }

  const onFilterChange = (
    filter: FilterByInterface | FilterByInterface[],
    resetDefaultFilters: boolean = true,
    forceResetOnFilter: boolean = false,
  ) => {
    let newFilters = stateRef.current!.filters
    if ((filteringNotResetOnce && resetDefaultFilters) || forceResetOnFilter) {
      resetFiltersQuery()
      newFilters = filterByInitial.filter(fil => fil.nonResettable)
      setInitialFiltered(false)
    } else if (!resetDefaultFilters) {
      setInitialFiltered(false)
    }

    if (Array.isArray(filter)) {
      filter.forEach(filterItem => {
        newFilters = setNewFilter(filterItem, newFilters)
      })

      setFetchQuery({
        page: 1,
        filters: newFilters,
      })
    } else {
      newFilters = setNewFilter(filter, newFilters)

      setFetchQuery({
        page: 1,
        filters: newFilters,
      })
    }
  }

  return {
    data,
    setData,
    count,
    setCount,
    columns,
    stats,
    loading,
    nextPageLoading,
    metadata,
    onSortChange,
    fetchSelectors,
    resetFiltersAndSorting,
    sortBy: fetchQuery.sortBy,
    onFilterChange,
    filterBy: fetchQuery.filters,
    fetchNextPage,
    fetchChildren,
    refresh,
    refreshStats: fetchStats,
    fetchError,
    fetchQuery,
    updateRows: (selector, cb) => {
      setData(draft => draft.map(row => (selector(row) ? cb(row) : row)))
    },
  }
}

export const useOrdering = <T extends { id: number; pipeline_queue_position?: number }>(
  data: T[],
  setData: (data: T[]) => void,
  count?: number,
  refreshTable?: () => void,
  onAfterChange?: (
    requestData: UpdateOrderingInterface,
    originalData: T[],
    updatedData: T[],
  ) => void,
  ignoreChangeOrder?: (
    sourceIds: (number | string)[],
    activeIndex: number | null,
    targetIndex: number,
  ) => boolean,
) => {
  const [selectedOrderingIds, setSelectedOrderingIds] = useState<(number | string)[]>([])

  const updateOrder = useCallback(
    (sourceIds: (number | string)[], activeIndex: number, targetIndex: number) => {
      const ids = data.map(item => item.id)

      const orderedIds = multiDragReorder({
        selectedIds: sourceIds,
        source: activeIndex,
        destination: targetIndex,
        ids,
      })

      let shallow = [...data]

      shallow.sort((a, b) => {
        return orderedIds.indexOf(a.id!) - orderedIds.indexOf(b.id!)
      })

      shallow = shallow.map((item, i) => ({
        ...item,
        pipeline_queue_position: i + 1,
      }))

      setData(shallow)

      return shallow
    },
    [data, setData],
  )

  const onChangeOrder = useCallback(
    async (
      sourceIds: (number | string)[],
      activeIndex: number | null,
      targetIndex: number,
      positionNumber?: number,
    ) => {
      if (!ignoreChangeOrder?.(sourceIds, activeIndex, targetIndex)) {
        let activeIdx = activeIndex

        if (activeIdx === null) {
          activeIdx = data.findIndex(item => item.id === selectedOrderingIds[0])
        }

        const sortedSourceIds = [...sourceIds]

        sortedSourceIds.sort(
          (a, b) =>
            data.find(item => item.id === a)?.pipeline_queue_position! -
            data.find(item => item.id === b)?.pipeline_queue_position!,
        )

        const isMovingTop = activeIdx > targetIndex
        const offset = isMovingTop ? 0 : 1

        const targetId = data[targetIndex + offset]?.id

        const requestData: UpdateOrderingInterface = {
          item_object_ids: sortedSourceIds,
        }

        // if we moving to the bottom or outside the current page
        const movingOutside = targetId === undefined || targetIndex === -1

        let updatedData

        if (movingOutside) {
          requestData.target_position_number = positionNumber || -1
          updatedData = updateOrder(sortedSourceIds, activeIdx, data.length)
        } else {
          requestData.target_position_object_id = targetId
          updatedData = updateOrder(sortedSourceIds, activeIdx, targetIndex)
        }

        setTimeout(() => {
          setSelectedOrderingIds([])
        }, 300)

        await onAfterChange?.(requestData, [...data], updatedData)

        // if we moving outside we should refresh the table, because we have unloaded data
        if (movingOutside && count !== undefined && data.length < count) {
          refreshTable?.()
        }
      }
    },
    [data, ignoreChangeOrder, updateOrder, onAfterChange, refreshTable],
  )

  const onChangePosition = useCallback(
    (id: number, positionNumber: number) => {
      let targetIndex = data.findIndex(
        item => item.pipeline_queue_position === positionNumber,
      )
      const sourceIndex = data.findIndex(item => item.id === id)

      onChangeOrder([id], sourceIndex, targetIndex, positionNumber)
    },
    [data, onChangeOrder],
  )

  const moveToTop = useCallback(() => {
    onChangeOrder(selectedOrderingIds, null, 0)
  }, [onChangeOrder, selectedOrderingIds])

  const moveToBottom = useCallback(() => {
    onChangeOrder(selectedOrderingIds, null, -1)
  }, [onChangeOrder, selectedOrderingIds])

  return {
    selectedOrderingIds,
    setSelectedOrderingIds,
    onChangePosition,
    onChangeOrder,
    moveToTop,
    moveToBottom,
  }
}

export const mergeTableSettings = (
  userSettings: TableSettingsInterface,
  tableSettings: TableSettingsInterface,
): TableSettingsInterface => {
  const visible = uniq([...tableSettings.visible, ...userSettings.visible])
  const hidden = uniq([...tableSettings.hidden, ...userSettings.hidden]).filter(
    h => !visible.includes(h),
  )
  return {
    visible,
    hidden,
  }
}

export const useStoredTableSettings = (
  name: TableNames,
  initialTableSettings?: TableSettingsInterface,
) => {
  return useMemo(() => {
    try {
      const item = workspaceLocalStorage.getItem(
        LocalStorageKeys.TABLE_SETTINGS_KEY_TEMPLATE.replace('{}', name),
      )
      return mergeTableSettings(
        item ? JSON.parse(item) : { hidden: [], visible: [] },
        initialTableSettings ?? { hidden: [], visible: [] },
      )
    } catch (error) {
      console.warn(error)
      return undefined
    }
  }, [name, initialTableSettings])
}
