import React from 'react'
import { useTable } from '@components/TableV2/hooks'
import { getAuditTickets } from '@src/api/audit'
import { EntityTypes } from '@src/constants/api'
import { RESOLVING_FINDING_TICKETS } from '@src/constants/externalLinks'
import { TableNames } from '@src/constants/table'
import { FilterByInterface, RowInterface, SortByInterface } from '@src/interfaces/data'
import { AuditInterface } from '@src/interfaces/audit'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { EmployeeInterface } from '@src/interfaces/employees'
import { TeamInterface } from '@src/interfaces/teams'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import Table from '@src/components/TableV2/Table'

interface FindingsTabProps {
  data?: TeamInterface | EmployeeInterface | DepartmentInterface
  entity: EntityTypes
  initialFilter?: FilterByInterface[]
  initialSort?: SortByInterface[]
  row: RowInterface<AuditInterface>
  navigation?: React.ReactNode
}

const auditTableNames = {
  [EntityTypes.company]: TableNames.Findings,
  [EntityTypes.department]: TableNames.DepartmentAuditTickets,
  [EntityTypes.employee]: TableNames.EmployeeAuditTickets,
  [EntityTypes.team]: TableNames.TeamAuditTickets,
}

export const FindingsTab = ({
  data,
  entity,
  initialFilter,
  initialSort,
  row,
  navigation,
}: FindingsTabProps) => {
  const table = useTable<AuditInterface>(
    { getItems: getAuditTickets(entity, data?.id) },
    initialFilter,
    initialSort,
  )

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const openUrl = (rowData: AuditInterface) => {
    window.open(rowData.issue_link, '_blank')
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>{navigation}</Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={3}>
          {entity === EntityTypes.company && !isCommercial && (
            <MoreInfoButton href={RESOLVING_FINDING_TICKETS} />
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<AuditInterface>
          {...table}
          dataType="Audit ticket"
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          name={auditTableNames[entity]}
          noDataMessage="All audit tickets relevant to your team will appear here."
          onRowClick={openUrl}
          row={row}
          useWindowScroll
          hideCount
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
