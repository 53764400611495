import React from 'react'
import {
  Avatar,
  BoxProps,
  Cell,
  Group,
  Item,
  Text,
  textChain,
  VStack,
} from '@revolut/ui-kit'
import { CareersApplicationEducationInterface } from '@src/interfaces/careers'
import { formatSnakeCase } from '@src/utils/string'
import { formatExperienceDateRange } from '@src/utils/hiring'

interface Props extends BoxProps {
  data?: CareersApplicationEducationInterface[]
}

const EducationInfo = ({ data, ...props }: Props) => {
  if (!data?.length) {
    return null
  }

  return (
    <Group {...props}>
      <Item aria-label="Education">
        <Item.Avatar>
          <Avatar useIcon="Library" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Education</Item.Title>
        </Item.Content>
      </Item>

      <Cell>
        <VStack gap="s-4">
          {data.map((item, i) => (
            <Text display="block" key={i}>
              {textChain(
                item.university?.name,
                item.major?.name,
                formatSnakeCase(item.degree),
                item.gpa ? `${item.gpa} gpa` : undefined,
                formatExperienceDateRange(item.start_date, item.end_date),
              )}
            </Text>
          ))}
        </VStack>
      </Cell>
    </Group>
  )
}

export default EducationInfo
