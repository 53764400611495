import React from 'react'
import { Flex, Token, Icon, Text } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import pluralize from 'pluralize'

interface RelatedKPIsProps {
  kpis: IdAndName[]
}

export const RelatedKPIs = ({ kpis }: RelatedKPIsProps) => {
  if (kpis.length === 0) {
    return <>-</>
  }

  return (
    <Flex gap="s-4" alignItems="center" color={Token.color.greyTone50}>
      <Icon name="Limit" size={16} />
      <Text>{pluralize('metric', kpis.length, true)}</Text>
    </Flex>
  )
}
