import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FilterButton } from '@revolut/ui-kit'
import {
  groupChannelsColumn,
  groupCreationDateColumn,
  groupMembersCountColumn,
  groupNameColumn,
  groupOwnerColumn,
  groupParentColumn,
  groupStatusColumn,
  groupTypeColumn,
} from '@src/constants/columns/communicationGroups'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'
import { getCommunicationGroupsList } from '@src/api/communicationGroups'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetCommunicationSettings } from '@src/api/settings'
import Table from '@components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const rows: RowInterface<CommunicationGroupInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.COMMUNICATION_GROUPS.VIEW.GROUP, { id })),
  cells: [
    {
      ...groupNameColumn,
      width: 150,
    },
    {
      ...groupParentColumn,
      width: 150,
    },
    {
      ...groupTypeColumn,
      width: 150,
    },
    {
      ...groupMembersCountColumn,
      width: 50,
    },
    {
      ...groupChannelsColumn,
      width: 50,
    },
    {
      ...groupCreationDateColumn,
      width: 80,
    },
    {
      ...groupOwnerColumn,
      width: 150,
    },
    {
      ...groupStatusColumn,
      width: 100,
    },
  ],
}

export const CommunicationGroupsTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_COMMUNICATION_GROUPS_ASSIGNED_TO_ME,
    false,
  )

  const { data: communicationSettings } = useGetCommunicationSettings()

  const user = useSelector(selectUser)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const permissions = useSelector(selectPermissions)
  const table = useTable<CommunicationGroupInterface>(
    {
      getItems: getCommunicationGroupsList,
    },
    getInitialFilters(),
  )
  const canAdd = permissions?.includes(PermissionTypes.AddCommunicationGroup)

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <Table.Widget>
      <Table.Widget.Filters>
        <Table.Search onFilter={table.onFilterChange} variant="compact" />
        <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
          My Groups
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        {canAdd ? (
          <PrimaryAction
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.COMMUNICATION_GROUPS.EDIT, {})}
            useIcon="Plus"
          >
            Create group
          </PrimaryAction>
        ) : null}
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<CommunicationGroupInterface>
          name={TableNames.CommunicationGroups}
          useWindowScroll
          dataType="Group"
          row={rows}
          {...table}
          hiddenCells={{
            [groupChannelsColumn.idPoint]:
              !communicationSettings?.enable_automatic_slack_channel_and_group_creation,
          }}
          emptyState={<EmptyTableRaw title="Communication groups will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
