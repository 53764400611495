import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import React from 'react'
import {
  DATA_PROTECTION_POLICY_EU_UK,
  DATA_PROTECTION_POLICY_GLOBAL,
  EMPLOYEE_PRIVACY_NOTICE_GLOBAL,
  INFORMATION_SECURITY_POLICY,
} from '@src/constants/externalLinks'
import { Circle, Group, Item } from '@revolut/ui-kit'
import { Document } from '@revolut/icons'

interface DPPType {
  title: string
  link: string
}

const DPP_LINKS: Array<DPPType> = [
  {
    title: 'Information Security Policy',
    link: INFORMATION_SECURITY_POLICY,
  },
  {
    title: 'Data Protection Policy - EU & UK',
    link: DATA_PROTECTION_POLICY_EU_UK,
  },
  {
    title: 'Data Protection Policy - Global',
    link: DATA_PROTECTION_POLICY_GLOBAL,
  },
  {
    title: 'Global Privacy Notice for Employees',
    link: EMPLOYEE_PRIVACY_NOTICE_GLOBAL,
  },
]

const PrivacyPolicies = () => {
  return (
    <PageWrapper>
      <PageHeader title="Data & privacy policies" backUrl={ROUTES.MAIN} />
      <PageBody>
        <Group>
          {DPP_LINKS.map(link => (
            <Item
              key={link.link}
              variant="disclosure"
              type="button"
              use="a"
              href={link.link}
              target="_blank"
            >
              <Item.Avatar mr="s-16">
                <Circle bg="blue-10" size={40}>
                  <Document color="blue" size={24} />
                </Circle>
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{link.title}</Item.Title>
              </Item.Content>
            </Item>
          ))}
        </Group>
      </PageBody>
    </PageWrapper>
  )
}

export default PrivacyPolicies
