import {
  DetailsCell,
  Icon,
  Token,
  Text,
  HStack,
  TabBar,
  Box,
  Avatar,
} from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { TargetsList } from '../Widgets/Targets/TargetsList'
import { TargetsCharts } from './TargetsCharts'
import {
  GoalKpiDetails,
  GoalPreviewInterface,
  GoalUpdateType,
} from '@src/interfaces/goals'

import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { useGoalTargetsTable } from '@src/features/Goals/common/useGoalTargetsTable'

import { GoalTargetSidebarForm } from '@src/features/Goals/GoalTargetSidebarForm'
import { IdAndName } from '@src/interfaces'
import { goalsRequests } from '@src/api/goals'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalCycleSelectType } from './types'

export const TargetsPreviewWidget = ({
  cycle,
  onChange,
  updateType,
}: {
  cycle: GoalCycleSelectType
  onChange: (tab: 'chart' | 'table') => void
  updateType?: IdAndName<GoalUpdateType>
}) => {
  const [selectedTarget, setSelectedTarget] = useState<GoalKpiDetails>()
  const [tab, setTab] = useLocalStorage<'chart' | 'table'>(
    'preferable_goal_target_preview_mode',
    'table',
  )

  const { reset } = useLapeContext<GoalPreviewInterface>()

  const refetchGoal = async (id?: string | number) => {
    if (id) {
      const { data } = await goalsRequests.get({ id: String(id) })
      reset(data)
    }
  }

  const targetsTable = useGoalTargetsTable(cycle)

  useEffect(() => {
    if (cycle.id) {
      targetsTable.resetFiltersAndSorting([
        {
          columnName: 'cycle__id',

          filters: [{ id: String(cycle.id), name: cycle.name }],
        },
      ])
    } else {
      targetsTable.resetFiltersAndSorting()
    }
  }, [cycle])

  useEffect(() => {
    onChange(tab)
  }, [tab])

  if (!targetsTable.data && !targetsTable.loading) {
    return null
  }

  return (
    <>
      <DetailsCell p={0} pb={0}>
        <DetailsCell.Title px="s-16">
          <HStack space="s-8" align="center">
            <Avatar useIcon="TurboTransfer" />
            <Text variant="h6" color={Token.color.greyTone50}>
              Metrics
            </Text>
          </HStack>
        </DetailsCell.Title>
        <DetailsCell.Content px="s-16" style={{ alignContent: 'center' }}>
          <TabBar
            variant="segmented"
            value={tab}
            onChange={selected => selected && setTab(selected)}
          >
            <TabBar.Item to="chart" aria-label="chart">
              <Text lineHeight="secondary" use="div" mx="s-8">
                <Icon name="BarChart" size={14} />
              </Text>
            </TabBar.Item>
            <TabBar.Item to="table" aria-label="table">
              <Text lineHeight="h2" use="div" mx="s-8">
                <Icon name="16/ListBullet" size={14} />
              </Text>
            </TabBar.Item>
          </TabBar>
        </DetailsCell.Content>
        <DetailsCell.Note>
          {tab === 'table' ? (
            <Box mt="s-16">
              <TargetsList
                onSelected={setSelectedTarget}
                viewMode
                table={targetsTable}
                updateType={updateType}
              />
            </Box>
          ) : (
            <TargetsCharts
              targets={targetsTable.data}
              onEdit={setSelectedTarget}
              updateType={updateType}
            />
          )}
        </DetailsCell.Note>
      </DetailsCell>
      <GoalTargetSidebarForm
        target={selectedTarget}
        onClose={() => setSelectedTarget(undefined)}
        onAfterSubmit={(metric: GoalKpiDetails) => {
          targetsTable.refresh()
          refetchGoal(metric.goal?.id)
          setSelectedTarget(undefined)
        }}
      />
    </>
  )
}
