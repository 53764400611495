import React from 'react'
import { RiskStatsInterface } from '@src/interfaces/risk'

interface DataInterface {
  kris_above_level_1?: number
  kris_above_level_2?: number
  kris_above_level_3?: number
}

interface Props {
  data: DataInterface
}

export const statsToKri = (stats?: RiskStatsInterface) =>
  stats
    ? {
        kris_above_level_1: stats.low,
        kris_above_level_2: stats.medium,
        kris_above_level_3: stats.high,
      }
    : {}

const RiskCircles = ({ data }: Props) => {
  return (
    <>
      {(data.kris_above_level_1 || 0) +
        (data.kris_above_level_2 || 0) +
        (data.kris_above_level_3 || 0)}
    </>
  )
}

export default RiskCircles
