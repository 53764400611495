import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
  analyticsDashboardRelatedGoalsColumn,
} from '@src/constants/columns/analyticsDashboard'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { pathToUrl } from '@src/utils/router'
import { EmployeeInterface } from '@src/interfaces/employees'
import { OrderingDataType } from '@src/interfaces/ordering'
import { dashboardTypeRouteMap } from '@src/pages/Forms/DataAnalyticsDashboardForm/constants'

interface Props {
  data: EmployeeInterface
}

const row: RowInterface<GenericAnalyticsDashboardInterface> = {
  linkToForm: ({ id, dashboard_type }) =>
    navigateTo(
      pathToUrl(
        pathToUrl(dashboardTypeRouteMap[dashboard_type], {
          id,
        }),
      ),
    ),
  cells: [
    {
      ...analyticsDashboardNameColumn,
      width: 200,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: 100,
    },
    {
      ...analyticsDashboardRelatedGoalsColumn,
      width: 100,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 40,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 40,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 80,
    },
  ],
}

export const AnalyticsDashboards = ({ data }: Props) => {
  const initialFilter = [
    {
      filters: [
        {
          name: OrderingDataType.Employees,
          id: OrderingDataType.Employees,
        },
      ],
      columnName: 'parent_type',
      nonResettable: true,
    },
    {
      filters: [{ name: data.full_name, id: data.id }],
      columnName: 'parent_id',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'position_number',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  return (
    <AnalyticsDashboardsTab
      data={{ id: data.id, name: data.full_name, field_options: data.field_options }}
      entity={EntityTypes.employee}
      row={row}
      initialFilter={initialFilter}
      initialSort={initialSortBy}
    />
  )
}
