import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { interviewFeedbackFormRequests } from '@src/api/recruitment/interviewFeedback'
import {
  InterviewFeedbackStatus,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import merge from 'lodash/merge'
import { GeneralV2 } from '@src/pages/Forms/InterviewFeedback/General/GeneralV2'

const InterviewFeedback = () => {
  const cacheHandler = (
    data: InterviewFeedbackInterface,
    cachedData: Partial<InterviewFeedbackInterface>,
  ) => {
    if (data.status === InterviewFeedbackStatus.completed) {
      return data
    }
    if (data.updated_date_time !== cachedData.updated_date_time) {
      return data
    }

    const { status, interview_stage, interviewer, field_options, ...cleanedCachedData } =
      cachedData

    return merge(data, cleanedCachedData)
  }
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL}>
        <Form<InterviewFeedbackInterface>
          api={interviewFeedbackFormRequests}
          useLocalStorageCaching
          onFetchData={cacheHandler}
        >
          <GeneralV2 />
        </Form>
      </Route>
    </Switch>
  )
}

export default InterviewFeedback
