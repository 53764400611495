import React from 'react'

import { Flex } from '@revolut/ui-kit'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TimeOffCalendar } from '@src/features/TimeOffCalendarTable/TimeOffCalendar'
import {
  useCalendarFilters,
  useTableCalendarSwitcher,
  useWeekMonthSwitcher,
} from '@src/features/TimeOffCalendarTable/hooks'
import { getDepartmentTimeOffCalendar } from '@src/api/timeOff'
import { TableNames } from '@src/constants/table'
import { TimeOffTable } from './TimeOffTable'
import {
  TableCalendarTabs,
  WeekMonthTabs,
} from '@src/features/TimeOffCalendarTable/constants'
import Table from '@src/components/TableV2/Table'

interface TimeOffProps {
  data: DepartmentInterface
  navigation?: React.ReactElement
}
export const TimeOff = ({ data, navigation }: TimeOffProps) => {
  const { isMonthView, clearCalendarFilters } = useCalendarFilters()
  const weekMonthSwitcher = useWeekMonthSwitcher(isMonthView)

  const tableCalendarSwitcher = useTableCalendarSwitcher(tab => {
    if (tab === TableCalendarTabs.Table) {
      clearCalendarFilters()
    }
  })

  return (
    <Table.Widget>
      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Table && (
        <TimeOffTable
          data={data}
          navigation={navigation}
          tabBarSwitcher={tableCalendarSwitcher.tabBar}
        />
      )}
      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar && (
        <TimeOffCalendar
          navigation={navigation}
          tableName={TableNames.DepartmentTimeOffCalendar}
          tabBarSwitcher={
            <Flex gap="s-8">
              {weekMonthSwitcher.tabBar}
              {tableCalendarSwitcher.tabBar}
            </Flex>
          }
          data={data}
          weekMonthTab={weekMonthSwitcher.currentTab || WeekMonthTabs.Week}
          getItems={getDepartmentTimeOffCalendar(data.id)}
        />
      )}
    </Table.Widget>
  )
}
