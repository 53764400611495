import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route, Switch } from 'react-router-dom'
import PrivacyPolicies from '@src/pages/UserPreferences/PrivacyPolicies/PrivacyPolicies'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const UserPreferences = () => {
  return (
    <>
      <Switch>
        <Route exact path={ROUTES.PREFERENCES.PRIVACY}>
          <HideIfCommercial>
            <PrivacyPolicies />
          </HideIfCommercial>
        </Route>
        <Route>
          <InternalRedirect to={ROUTES.MAIN} />
        </Route>
      </Switch>
    </>
  )
}

export default UserPreferences
