import React, { PropsWithChildren, useMemo } from 'react'
import { useTableReturnType } from '@components/TableV2/hooks'
import { RowInterface } from '@src/interfaces/data'
import {
  RiskLevel,
  RiskStatsInterface,
  RiskSummaryInterface,
  RiskTabData,
} from '@src/interfaces/risk'
import {
  riskActionColumn,
  riskAssigneeColumn,
  riskCategoryColumn,
  riskDueDateColumn,
  riskLevelColumn,
  riskReasonColumn,
  riskSolutionColumn,
  riskStatusColumn,
  riskSummaryColumn,
} from '@src/constants/columns/risk'
import { MoreBar, FilterButton, chain } from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { teamNameColumn } from '@src/constants/columns/team'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import upperFirst from 'lodash/upperFirst'

interface Props {
  data?: RiskTabData
  table: useTableReturnType<RiskSummaryInterface, RiskStatsInterface>
  isEmployee?: boolean
  isCompany?: boolean
  useWrapper?: boolean
}

const ROW = (isEmployee: boolean): RowInterface<RiskSummaryInterface> => ({
  cells: [
    {
      ...riskSummaryColumn,
      width: 340,
    },
    {
      ...riskLevelColumn,
      width: 140,
    },
    {
      ...riskCategoryColumn,
      width: 140,
    },
    {
      ...(isEmployee ? teamNameColumn : riskAssigneeColumn),
      width: 140,
    },
    {
      ...riskReasonColumn,
      width: 160,
    },
    {
      ...riskSolutionColumn,
      width: 240,
    },
    {
      ...riskActionColumn,
      width: 80,
    },
    {
      ...riskDueDateColumn,
      width: 100,
    },
    {
      ...riskStatusColumn,
      width: 100,
    },
  ],
})

const RiskLevelFilterButton = ({
  riskLevel,
  table,
  children,
}: PropsWithChildren<{
  riskLevel: RiskLevel
  table: Props['table']
}>) => {
  const isActiveFilter = !!table.filterBy
    .find(f => f.columnName === 'risk_level')
    ?.filters.find(f => f.id === riskLevel)

  const onToggleRiskLevelFilter = () => {
    table.onFilterChange({
      filters: !isActiveFilter ? [{ name: riskLevel, id: riskLevel }] : [],
      columnName: 'risk_level',
      nonResettable: true,
    })
  }

  return (
    <FilterButton onClick={onToggleRiskLevelFilter} active={isActiveFilter}>
      {chain(children, table.stats?.[riskLevel] || 0)}
    </FilterButton>
  )
}

const Summary = ({ table, data, isEmployee = false, isCompany = false }: Props) => {
  const row = useMemo(() => ROW(isEmployee), [isEmployee])

  return (
    <>
      {!isCompany && !isEmployee ? (
        <Table.Widget.Filters>
          {Object.values(RiskLevel).map(riskLevel => (
            <RiskLevelFilterButton riskLevel={riskLevel} table={table} key={riskLevel}>
              {upperFirst(riskLevel)}
            </RiskLevelFilterButton>
          ))}
        </Table.Widget.Filters>
      ) : null}
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {data?.risk_score && (
            <MoreBar.Action
              target="_blank"
              rel="noreferrer noopener"
              href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1710528923?atlOrigin=eyJpIjoiMTE3MzQ0MDA2NjdkNGM3YmJlYmZjNmE1MWM3OTFkYWYiLCJwIjoiY29uZmx1ZW5jZS1jaGF0cy1pbnQifQ"
              use="a"
              useIcon={InfoOutline}
            >
              What are the risk and control scores?
            </MoreBar.Action>
          )}
          <MoreBar.Action
            target="_blank"
            rel="noreferrer noopener"
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1430881442/Risks+and+Controls+Identification+and+Assessment"
            use="a"
            useIcon={InfoOutline}
          >
            How to manage risks?
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<RiskSummaryInterface, RiskStatsInterface>
          name={TableNames.RiskSummary}
          useWindowScroll
          dataType="Risk"
          row={row}
          {...table}
          noDataMessage="This team has no data on their Risk"
          hideCount
        />
      </Table.Widget.Table>
    </>
  )
}

export default Summary
