import React from 'react'
import { useTableReturnType } from '@components/TableV2/hooks'
import { RowInterface } from '@src/interfaces/data'
import {
  RiskControlsInterface,
  RiskLevel,
  RiskStatsInterface,
  RiskTabData,
} from '@src/interfaces/risk'
import {
  riskActionsColumn,
  riskControlEffectivenessColumn,
  riskIdColumn,
  riskNameColumn,
  riskNextReviewDateColumn,
  riskOwnerColumn,
  riskResidualScoreColumn,
  riskTargetControlDateColumn,
  riskTypeL2Column,
  riskUrlColumn,
} from '@src/constants/columns/risk'
import { MoreBar, Token } from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { teamNameColumn } from '@src/constants/columns/team'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'

interface Props {
  data?: RiskTabData
  table: useTableReturnType<RiskControlsInterface, RiskStatsInterface>
  isEmployee?: boolean
  useWrapper?: boolean
}

const ROW = (isEmployee: boolean): RowInterface<RiskControlsInterface> => ({
  highlight: data => {
    if (data.risk_level === RiskLevel.high) {
      return Token.color.red_20
    }
    return ''
  },
  cells: [
    {
      ...riskIdColumn,
      width: 80,
    },
    {
      ...riskTypeL2Column,
      width: 150,
    },
    {
      ...riskNameColumn,
      width: 250,
    },
    {
      ...riskResidualScoreColumn,
      width: 120,
    },
    {
      ...(isEmployee ? teamNameColumn : riskOwnerColumn),
      width: 140,
    },
    {
      ...riskControlEffectivenessColumn,
      width: 80,
    },
    {
      ...riskTargetControlDateColumn,
      width: 160,
    },
    {
      ...riskNextReviewDateColumn,
      width: 160,
    },
    {
      ...riskActionsColumn,
      width: 180,
    },
    {
      ...riskUrlColumn,
      width: 100,
    },
  ],
})

const Controls = ({ table, data, isEmployee = false }: Props) => {
  return (
    <>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {data?.risk_score && (
            <MoreBar.Action
              target="_blank"
              rel="noreferrer noopener"
              href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1710528923/Risk+Profile+Indices+CCI+and+RCI"
              use="a"
              useIcon={InfoOutline}
            >
              What is risk score?
            </MoreBar.Action>
          )}
          <MoreBar.Action
            target="_blank"
            rel="noreferrer noopener"
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1430881442/Risks+and+Controls+Identification+and+Assessment"
            use="a"
            useIcon={InfoOutline}
          >
            How to manage risks & controls?
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<RiskControlsInterface, RiskStatsInterface>
          name={TableNames.Controls}
          useWindowScroll
          dataType="Risk"
          row={ROW(isEmployee)}
          {...table}
          noDataMessage="This team has no data on their Risk"
          hideCount
        />
      </Table.Widget.Table>
    </>
  )
}

export default Controls
