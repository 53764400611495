import React from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import PreviewScorecard from '@src/pages/Forms/InterviewScorecardTemplate/Preview/PreviewScorecard'
import { PageBody } from '@src/components/Page/PageBody'
import SettingsButtons, { CopyButton, EditButton } from '@src/features/SettingsButtons'
import { DeleteButtonLape } from '../../FormButtons'
import { ROUTES } from '@src/constants/routes'
import { interviewScorecardTemplateFormRequests } from '@src/api/interviewScorecardTemplate'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'

type PreviewParams = {
  id: string
}

type PreviewBodyProps = {
  id: number
  editAction?: React.ReactNode
  hideCopyButton?: React.ReactNode
  hideSettingsButtons?: boolean
}

export const PreviewBody = connect(
  ({ id, editAction, hideCopyButton, hideSettingsButtons }: PreviewBodyProps) => {
    const { values } = useLapeContext<InterviewScorecardTemplateInterface>()
    return (
      <>
        {!hideSettingsButtons && (
          <SettingsButtons mb="s-32">
            {editAction ?? (
              <EditButton
                route={pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS, {
                  id,
                })}
              />
            )}
            <DeleteButtonLape<InterviewScorecardTemplateInterface>
              useMoreBar
              data={values}
              backUrl={ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATES}
              deleteApi={interviewScorecardTemplateFormRequests.delete!}
              title="Scorecard"
            />
            {!hideCopyButton && (
              <CopyButton
                isVisible
                afterSubmitUrl={pathToUrl(
                  ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS,
                  {},
                )}
                replaceValues={{
                  sections: values.sections?.map(({ id: sectionId, ...section }) => ({
                    ...section,
                    questions: section.questions?.map(
                      ({ id: questionId, ...question }) => question,
                    ),
                  })),
                }}
              />
            )}
          </SettingsButtons>
        )}
        <PreviewScorecard id={id} data={values} />
      </>
    )
  },
)

const General = () => {
  const params = useParams<PreviewParams>()
  return (
    <PageBody>
      <PreviewBody id={+params.id} />
    </PageBody>
  )
}

export default General
