import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Icon from '../Icon/Icon'
import Tooltip from '@components/Tooltip/Tooltip'
import { ReviewInterface } from '@src/interfaces/performance'
import { Avatar, Color, IconName, Token } from '@revolut/ui-kit'
import {
  BtnWrapper,
  ButtonTooltip,
  Container,
  Count,
  StyledTooltip,
} from '@src/components/AssessButtons/CommonSC'

export enum AssessButtonTypes {
  positive = 'yes',
  negative = 'no',
  neutral = 'dont_know',
  unknown = 'unknown',
}

interface TooltipInterface {
  [AssessButtonTypes.positive]: ReviewInterface[]
  [AssessButtonTypes.negative]: ReviewInterface[]
  [AssessButtonTypes.neutral]: ReviewInterface[]
  [AssessButtonTypes.unknown]: ReviewInterface[]
}

export interface AssessButtonsProps {
  disabled?: boolean
  checked: AssessButtonTypes[]
  onChange?: (value: AssessButtonTypes) => void
  className?: string
  showCounts?: boolean
  reviewsList: TooltipInterface
  error?: boolean
  visibleButtons?: AssessButtonTypes[]
  name?: string
}

const ErrorTooltip = styled(Tooltip)`
  position: absolute;
  right: -23px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: ${Token.color.red};
`

type ButtonWithTooltipProps = {
  reviewsList: ReviewInterface[]
  disabled?: boolean
  checked: boolean
  onClick: () => void
  name?: string
  bg: Color
  bgChecked: Color
  color: Color
  colorChecked: Color
  label: React.ReactNode
  icon: IconName
  tooltipAlwaysVisible?: boolean
  type: AssessButtonTypes
}

const ButtonWithTooltip = ({
  name,
  onClick,
  reviewsList,
  checked,
  disabled,
  bgChecked,
  colorChecked,
  color,
  bg,
  label,
  icon,
  tooltipAlwaysVisible,
  type,
}: ButtonWithTooltipProps) => {
  return (
    <StyledTooltip
      placement="top"
      body={<ButtonTooltip reviewsList={reviewsList}>{label}</ButtonTooltip>}
      hide={tooltipAlwaysVisible ? false : !reviewsList.length}
    >
      <Avatar
        use={disabled ? undefined : 'button'}
        type="button"
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        useIcon={icon}
        bg={checked ? bgChecked : bg}
        color={checked ? colorChecked : color}
        onClick={() => {
          if (disabled) {
            return
          }
          onClick()
        }}
        data-checked={checked}
        data-testid={`${name}_${type}`}
        size={32}
      />
    </StyledTooltip>
  )
}

const AssessButtons = ({
  name,
  disabled,
  checked,
  onChange,
  className,
  reviewsList,
  showCounts,
  error,
  visibleButtons = [
    AssessButtonTypes.positive,
    AssessButtonTypes.negative,
    AssessButtonTypes.neutral,
  ],
}: AssessButtonsProps) => {
  const [currentlyChecked, setCurrentlyChecked] = useState<AssessButtonTypes>()

  useEffect(() => {
    setCurrentlyChecked(checked[0])
  }, [checked])

  const onButtonClick = (type: AssessButtonTypes) => {
    setCurrentlyChecked(type)
    onChange && onChange(type)
  }

  const positiveBtnChecked =
    !!checked?.includes(AssessButtonTypes.positive) ||
    currentlyChecked === AssessButtonTypes.positive

  const negativeBtnChecked =
    !!checked?.includes(AssessButtonTypes.negative) ||
    currentlyChecked === AssessButtonTypes.negative

  const neutralBtnChecked =
    !!checked?.includes(AssessButtonTypes.neutral) ||
    currentlyChecked === AssessButtonTypes.neutral

  const unknownBtnChecked =
    !!checked?.includes(AssessButtonTypes.unknown) ||
    currentlyChecked === AssessButtonTypes.unknown

  const countLineHeight = 32

  return (
    <Container className={className}>
      {visibleButtons.includes(AssessButtonTypes.positive) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessButtonTypes.positive].length ? (
            <Count lineHeight={countLineHeight}>
              {reviewsList[AssessButtonTypes.positive].length}
            </Count>
          ) : null}

          <ButtonWithTooltip
            onClick={() => onButtonClick(AssessButtonTypes.positive)}
            name={name}
            checked={positiveBtnChecked}
            bg={Token.color.greyTone8}
            bgChecked={Token.color.success}
            color={Token.color.greyTone50}
            colorChecked={Token.color.white}
            reviewsList={reviewsList[AssessButtonTypes.positive]}
            disabled={disabled}
            label="Yes"
            icon="16/Check"
            type={AssessButtonTypes.positive}
          />
        </BtnWrapper>
      )}

      {visibleButtons.includes(AssessButtonTypes.negative) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessButtonTypes.negative].length ? (
            <Count lineHeight={countLineHeight}>
              {reviewsList[AssessButtonTypes.negative].length}
            </Count>
          ) : null}

          <ButtonWithTooltip
            onClick={() => onButtonClick(AssessButtonTypes.negative)}
            name={name}
            checked={negativeBtnChecked}
            bg={Token.color.greyTone8}
            bgChecked={Token.color.danger}
            color={Token.color.greyTone50}
            colorChecked={Token.color.white}
            reviewsList={reviewsList[AssessButtonTypes.negative]}
            disabled={disabled}
            label="No"
            icon="16/Cross"
            type={AssessButtonTypes.negative}
          />
        </BtnWrapper>
      )}

      {visibleButtons.includes(AssessButtonTypes.neutral) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessButtonTypes.neutral].length ? (
            <Count lineHeight={countLineHeight}>
              {reviewsList[AssessButtonTypes.neutral].length}
            </Count>
          ) : null}
          <ButtonWithTooltip
            onClick={() => onButtonClick(AssessButtonTypes.neutral)}
            name={name}
            checked={neutralBtnChecked}
            bg={Token.color.greyTone8}
            bgChecked={Token.color.greyTone50}
            color={Token.color.greyTone50}
            colorChecked={Token.color.white}
            reviewsList={reviewsList[AssessButtonTypes.neutral]}
            disabled={disabled}
            label={<>Don't&nbsp;know</>}
            icon="RadiobuttonOff"
            tooltipAlwaysVisible
            type={AssessButtonTypes.neutral}
          />
        </BtnWrapper>
      )}

      {visibleButtons.includes(AssessButtonTypes.unknown) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessButtonTypes.unknown].length ? (
            <Count>{reviewsList[AssessButtonTypes.unknown].length}</Count>
          ) : null}

          <ButtonWithTooltip
            onClick={() => onButtonClick(AssessButtonTypes.unknown)}
            name={name}
            checked={unknownBtnChecked}
            bg={Token.color.greyTone8}
            bgChecked={Token.color.greyTone50}
            color={Token.color.greyTone50}
            colorChecked={Token.color.white}
            reviewsList={[]}
            disabled={disabled}
            label="Unknown"
            icon="Minus"
            type={AssessButtonTypes.unknown}
          />
        </BtnWrapper>
      )}

      {error && (
        <ErrorTooltip placement="right" text="You need to select a rating">
          <Icon type={'WarningOutlined'} size={'tiny'} />
        </ErrorTooltip>
      )}
    </Container>
  )
}

export default AssessButtons
