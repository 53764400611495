import React, { useEffect } from 'react'
import { ScoreType, ViewType } from '../types'
import { IdAndName } from '@src/interfaces'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  engagementQuestionsActionsColumn,
  engagementResultsAverageDistributionColumn,
  engagementResultsAverageScoreColumn,
  engagementResultsNpsDistributionColumn,
  engagementResultsNpsScoreColumn,
  engagementResultsQuestionCategoryNameColumn,
  engagementResultsQuestionNameColumn,
  engagementResultsQuestionTypeColumn,
  engagementResultsTrendAverageScoreColumn,
  engagementResultsTrendNpsScoreColumn,
} from '@src/constants/columns/engagementResults'
import { Token, Box } from '@revolut/ui-kit'
import { useTable } from '@src/components/Table/hooks'
import {
  getEngagementResultsTableRequests,
  getSurveyAdminResultQuestionsTableRequests,
} from '@src/api/engagement'
import {
  EngagementResultInterface,
  EngagementResultsEntities,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import { useCommentsSidebar } from './CommentsActionSidebar'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

interface Props {
  view: ViewType
  scoreType: ScoreType
  selectedRound: IdAndName
  surveyId: number
  entity?: { type: EngagementResultsScope; id: number }
  canSeeComments: boolean
}

const trendColumnTooltip = (
  <Box color={Token.color.background} p="s-8" minWidth={250}>
    This indicates how much the score for this question has increased or decreased since
    the last time it was asked to employees
  </Box>
)

const getRows = (
  setQuestionSidebar: (
    driver: EngagementResultInterface['driver'],
    question: EngagementResultInterface['question'],
  ) => void,
) => ({
  cells: [
    { ...engagementResultsQuestionNameColumn, width: 350 },
    { ...engagementResultsQuestionCategoryNameColumn, width: 100 },
    { ...engagementResultsQuestionTypeColumn, width: 120 },
    { ...engagementResultsAverageScoreColumn, width: 95 },
    { ...engagementResultsNpsScoreColumn, width: 95 },
    { ...engagementResultsAverageDistributionColumn, width: 300 },
    { ...engagementResultsNpsDistributionColumn, width: 300 },
    {
      ...engagementResultsTrendAverageScoreColumn,
      width: 95,
      headerTooltip: trendColumnTooltip,
    },
    {
      ...engagementResultsTrendNpsScoreColumn,
      width: 65,
      headerTooltip: trendColumnTooltip,
    },
    { ...engagementQuestionsActionsColumn(setQuestionSidebar), width: 40 },
  ],
})

export const ResultQuestions = ({
  view,
  scoreType,
  selectedRound,
  surveyId,
  entity,
  canSeeComments,
}: Props) => {
  const { setSidebar, commentsSidebar } = useCommentsSidebar(surveyId, entity)
  const table = useTable<EngagementResultInterface>(
    entity
      ? getEngagementResultsTableRequests(
          entity.id,
          surveyId,
          entity.type,
          EngagementResultsEntities.Questions,
        )
      : getSurveyAdminResultQuestionsTableRequests({ surveyId }),
    [
      {
        columnName: 'survey_round_id',
        filters: [{ id: selectedRound.id, name: String(selectedRound.id) }],
        nonInheritable: true,
        nonResettable: true,
      },
    ],
  )

  useEffect(() => {
    const currentSurveyRound = table.filterBy
      .find(({ columnName }) => columnName === 'survey_round_id')
      ?.filters.at(0)?.id
    if (selectedRound.id !== currentSurveyRound) {
      table.onFilterChange({
        columnName: 'survey_round_id',
        filters: [{ id: selectedRound.id, name: String(selectedRound.id) }],
        nonInheritable: true,
        nonResettable: true,
      })
    }
  }, [selectedRound])

  const hiddenCells = {
    [engagementResultsTrendAverageScoreColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsAverageDistributionColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsAverageScoreColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsNpsScoreColumn.idPoint]: scoreType === 'average',
    [engagementResultsNpsDistributionColumn.idPoint]: scoreType === 'average',
    [engagementResultsTrendNpsScoreColumn.idPoint]: scoreType === 'average',
    driver_actions: !canSeeComments,
  }

  return view === 'table' ? (
    <>
      <AdjustableTable
        name={TableNames.EngagementSurveysResultQuestions}
        row={getRows((driver, question) => setSidebar({ driver, question }))}
        emptyState={<EmptyTableRaw title="This survey has no results yet" />}
        hideCount
        hiddenCells={hiddenCells}
        useWindowScroll
        {...table}
      />
      {commentsSidebar}
    </>
  ) : null
}
