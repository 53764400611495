import { Box, Popover, Text, TextButton } from '@revolut/ui-kit'
import React from 'react'

interface GlobalSearchSectionProps {
  name: string
  children: React.ReactNode
  showMore?: () => void
}

const GlobalSearchSection = ({ name, children, showMore }: GlobalSearchSectionProps) => {
  const title = (
    <Text
      data-testid="global-search-section-title"
      color="grey-tone-50"
      use="div"
      variant="emphasis2"
    >
      {name}
    </Text>
  )
  const actions = showMore && (
    <TextButton fontSize={14} onClick={showMore}>
      See more
    </TextButton>
  )
  return (
    <Box mb="s-12">
      <Popover.Group
        title={title}
        actions={actions}
        data-testid="global-search-section-container"
      >
        {children}
      </Popover.Group>
    </Box>
  )
}

export default GlobalSearchSection
