import React from 'react'
import { useSelector } from 'react-redux'
import { HStack } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { documentsCollectionsRequests } from '@src/api/documentsCollections'
import {
  documentsCollectionsActionsColumn,
  documentsCollectionsTemplatesCountColumn,
  documentsCollectionsName,
} from '@src/constants/columns/documentsCollections'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { DocumentsCollectionsInterface } from '@src/interfaces/documentsCollections'
import { pathToUrl } from '@src/utils/router'
import { TableActionButton } from '@components/Button/TableActionButton'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { useTable } from '@components/TableV2/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const getRow = (canEdit: boolean): RowInterface<DocumentsCollectionsInterface> => ({
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_COLLECTION.PREVIEW, { id })),
  cells: [
    {
      ...documentsCollectionsName,
      width: 350,
    },
    {
      ...documentsCollectionsTemplatesCountColumn,
      width: 200,
    },
    {
      ...documentsCollectionsActionsColumn,
      width: 200,
      insert: ({ data }) => {
        return (
          <HStack space="s-12">
            {canEdit && (
              <TableActionButton
                onClick={e => {
                  e.stopPropagation()
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.DOCUMENT_COLLECTION.GENERAL, {
                      id: data.id,
                    }),
                  )
                }}
              >
                Edit
              </TableActionButton>
            )}
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                navigateTo(
                  pathToUrl(ROUTES.FORMS.DOCUMENT_COLLECTION.PREVIEW, {
                    id: data.id,
                  }),
                )
              }}
            >
              View
            </TableActionButton>
          </HStack>
        )
      },
    },
  ],
})

export const DocumentsCollections = () => {
  const permissions = useSelector(selectPermissions)

  const canAddDocumentsCollections = permissions?.includes(
    PermissionTypes.AddDocumentCollection,
  )
  const canEditDocumentsCollections = permissions?.includes(
    PermissionTypes.ChangeDocumentCollection,
  )

  const table = useTable<DocumentsCollectionsInterface>(documentsCollectionsRequests)

  return (
    <PageWrapper>
      <PageHeader backUrl={ROUTES.SETTINGS.DOCUMENTS} title="Documents Collections" />
      <PageBody maxWidth="100%">
        <Table.Widget>
          <Table.Widget.Actions>
            {canAddDocumentsCollections && (
              <PrimaryAction
                onClick={() => {
                  navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_COLLECTION.GENERAL))
                }}
                useIcon="Plus"
              >
                Create new collection
              </PrimaryAction>
            )}
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <AdjustableTable
              name={TableNames.DocumentCollections}
              noDataMessage="All documents collections will appear here"
              row={getRow(canEditDocumentsCollections)}
              useWindowScroll
              {...table}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
    </PageWrapper>
  )
}
