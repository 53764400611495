import React, { useEffect } from 'react'
import { Box, Flex, Text, Token, useMatchMedia } from '@revolut/ui-kit'
import useResizeObserver from 'use-resize-observer'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { connect } from 'lape'
import { css } from 'styled-components'
import mainHeaderState from './MainHeaderState'
import { defaultTheme } from '@src/styles/theme'
import { useBanner } from '@src/features/UIKitWithThemeProvider/BannerProvider'

interface MainHeaderProps {
  title?: string
  tabs?: TabBarTableNavigationInterface[] | null
  children?: React.ReactNode
}

const stickCss = css`
  position: sticky;
  top: 0;
  z-index: ${defaultTheme.zIndex.aboveMain + 1};
  background-color: ${Token.color.layoutBackground};
  padding-top: 64px;

  @media ${Token.media.md} {
    padding-top: 30px;
  }
`

const withDemoBarCss = css`
  top: 32px;
  margin-bottom: 28px;
  padding-top: 60px;
`

const containerCss = css`
  position: sticky;
  z-index: ${defaultTheme.zIndex.aboveMain + 1};
`

const MainHeader = ({ title, tabs, children }: MainHeaderProps) => {
  const { ref: stickyRef, height: headerHeight } = useResizeObserver()
  const { visible } = useBanner()
  const mdMedia = useMatchMedia(Token.media.md)

  useEffect(() => {
    if (headerHeight) {
      const mediaOffset = mdMedia ? 30 : 64
      mainHeaderState.height = headerHeight + (visible ? 60 : mediaOffset)
    }
  }, [headerHeight, visible, mdMedia])

  return (
    <Box
      mx="-s-16"
      px="s-16"
      css={[stickCss, visible ? withDemoBarCss : null]}
      ref={stickyRef}
    >
      <Flex pb="s-16" justifyContent="space-between" alignItems="center">
        <Text use="h1" variant="h5" data-event-key={title}>
          {title}
        </Text>
      </Flex>
      <Box css={containerCss}>
        {children}
        {tabs != null ? (
          <Box mb="s-16">
            <TabBarNavigation isSmall tabs={tabs} />
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default connect(MainHeader)
