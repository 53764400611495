import React, { useState } from 'react'
import { Avatar, Icon, Relative, Circle, IconName, AvatarSize } from '@revolut/ui-kit'
import { EntityAvatarUpload } from '@src/features/EntityAvatarUpload/EntityAvatarUpload'
import { MutationResult } from '@src/features/SettingsButtons/types'

interface EntityAvatarProps<T> {
  data: { icon?: IconName | null; id: number; avatar?: null | string }
  defaultIcon: IconName
  api?: () => MutationResult<T>
  apiUrl?: string
  canEdit: boolean
  size?: AvatarSize
  onSuccess?: (data?: { icon?: IconName | null; avatar?: null | string }) => void
}

export const EntityAvatar = <T extends {}>({
  data,
  defaultIcon,
  api,
  apiUrl,
  canEdit = false,
  size = 56,
  onSuccess,
}: EntityAvatarProps<T>) => {
  const [avatarUploadOpen, setAvatarUploadOpen] = useState(false)
  const [isMouseOver, setMouseOver] = useState(false)

  return (
    <>
      <Relative
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <Avatar
          size={size}
          useIcon={data.avatar ? undefined : data.icon || defaultIcon}
          image={data.avatar ? data.avatar : undefined}
          aria-label="Avatar"
        />
        {canEdit && isMouseOver ? (
          <Circle
            bg="modal-overlay"
            size={38}
            style={{
              position: 'absolute',
              top: '28px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'pointer',
            }}
            onClick={() => setAvatarUploadOpen(true)}
          >
            <Icon name="Camera" color="white" size={24} />
          </Circle>
        ) : null}
      </Relative>
      {api && (
        <EntityAvatarUpload
          open={avatarUploadOpen}
          onClose={() => setAvatarUploadOpen(false)}
          onSuccess={onSuccess}
          api={api}
          apiUrl={apiUrl}
          id={data.id}
          icon={data.icon}
        />
      )}
    </>
  )
}
