import React from 'react'
import { Flex, Token, Icon, Text } from '@revolut/ui-kit'
import { Id } from '@src/interfaces'
import pluralize from 'pluralize'

interface Props {
  goals: (Id & { name?: string })[]
}

export const RelatedGoals = ({ goals }: Props) => {
  if (goals.length === 0) {
    return <>-</>
  }

  return (
    <Flex gap="s-4" alignItems="center" color={Token.color.greyTone50}>
      <Icon name="Limit" size={16} />
      <Text>{pluralize('goal', goals.length, true)}</Text>
    </Flex>
  )
}
