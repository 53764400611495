import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  VStack,
  Text,
  Popup,
  Subheader,
  IconButton,
  Token,
  TextSkeleton,
  DetailsSkeleton,
  DetailsCellSkeleton,
  useStatusPopup,
  StatusPopup,
  MoreBar,
} from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { UserCardOneRow } from '@src/components/UserCard/UserCard'
import {
  getTalentByComment,
  getTalentCommentsAPI,
  useGetTalentByComment,
} from '@src/api/talent'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import ChatMessagesList from '@src/components/Chat/ChatMessagesList'
import { ChatMessageType } from '@src/components/Chat/common'
import ChatTextEditor, {
  ChatTextEditorVariant,
} from '@src/components/Chat/ChatTextEditor/ChatTextEditor'
import { ChatMessageInterface } from '@src/interfaces/chat'
import styled from 'styled-components'
import { pushError, successNotification } from '@src/store/notifications/actions'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { getAvatarUrl } from '@src/utils/employees'

const PinnedSubheader = styled(Subheader)`
  padding-top: 0px;
`

const CommentsSkeleton = styled(DetailsCellSkeleton)`
  height: 95px;
`

const MessageInputSkeleton = styled(DetailsSkeleton)`
  height: 55px;
`

const PerformanceCalibrationActionPopup = (props: {
  open: boolean
  notification: NotificationsInterface
  onClose: () => void
  onResolved: (id: NotificationsInterface['id']) => void
}) => {
  const { open, notification, onClose, onResolved } = props
  const { data: talent, isLoading, error } = useGetTalentByComment(notification.item_id)
  const commentsApi = getTalentCommentsAPI(talent?.id || null)
  const {
    data: comment,
    isLoading: isLoadingComment,
    error: loadCommentError,
  } = commentsApi.useGetComment(talent?.id ? notification.item_id : null)
  const statusPopup = useStatusPopup()
  const [commentsToShow, setCommentsToShow] = useState<ChatMessageInterface[]>([])

  const summaryPageUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_SUMMARY, {
    id: talent?.employee.id,
  })

  const showFallbackUI = isLoading || isLoadingComment

  const failure = error || loadCommentError

  useEffect(() => {
    if (failure) {
      const message =
        failure.response?.data?.join('\n') || failure.message || 'Something went wrong'
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to receive details</StatusPopup.Title>
          <StatusPopup.Description>{message}</StatusPopup.Description>
        </StatusPopup>,
      )
      onClose()
    }
  }, [failure])

  if (failure) {
    return null
  }

  return (
    <Popup size="md" open={open} onClose={onClose} aria-label="calibration comments">
      <PinnedSubheader>
        <Subheader.Title>
          <Text variant="h5">Comments</Text>
        </Subheader.Title>
        <Subheader.Side>
          <IconButton
            useIcon="Cross"
            onClick={onClose}
            aria-label="close comment"
            color={Token.color.foreground}
          />
        </Subheader.Side>
      </PinnedSubheader>
      <VStack space="s-16">
        {showFallbackUI ? (
          <>
            <VStack space="s-8">
              <TextSkeleton width="350px" />
              <TextSkeleton width="200px" />
            </VStack>
            <CommentsSkeleton />
            <MessageInputSkeleton />
          </>
        ) : (
          <>
            <VStack space="s-4">
              <UserCardOneRow
                displayName={
                  talent?.employee.display_name || talent?.employee.full_name || ''
                }
                avatar={getAvatarUrl(talent?.employee.avatar)}
                position={`${talent?.employee.job_title} - ${talent?.employee.seniority?.name}`}
                userLink={getLocationDescriptor(summaryPageUrl)}
              />
              <Text variant="caption" color={Token.color.greyTone50}>
                {talent?.cycle.name} Performance Review
              </Text>
            </VStack>
            <ChatMessagesList
              onEdit={commentsApi.editComment}
              onArchive={commentsApi.archiveComment}
              onResolve={async commentId => {
                await commentsApi.resolveComment(commentId)
                onResolved(notification.id)
              }}
              refetch={() => Promise.resolve()}
              type={ChatMessageType.Comment}
              isLoading={isLoadingComment}
              data={[comment, ...commentsToShow].filter(Boolean)}
            />
            <ChatTextEditor
              variant={ChatTextEditorVariant.Input}
              onSubmit={async (message, createTask) => {
                const { data } = await commentsApi.addComment(message, createTask)
                setCommentsToShow([...commentsToShow, data])
              }}
            />
          </>
        )}
      </VStack>
    </Popup>
  )
}

export const PerformanceCalibrationActions = ({
  notification,
  onResolved,
}: {
  notification: NotificationsInterface
  onResolved: (id: NotificationsInterface['id']) => void
}) => {
  const [open, setOpen] = useState(false)
  const [pending, setPending] = useState(false)
  // we don't want to render PerformanceCalibrationActionPopup before first open to reduce number of requests
  // but once opened we want to keep it in DOM even when closed to keep the state
  const [beenUsed, setBeenUsed] = useState(false)

  const markAsDone = async () => {
    setPending(true)

    try {
      const { data: talent } = await getTalentByComment(notification.item_id)
      const commentsApi = getTalentCommentsAPI(talent.id)
      await commentsApi.resolveComment(notification.item_id)
      successNotification('Comment marked as completed')
      onResolved(notification.id)
    } catch (err) {
      pushError(err)
    } finally {
      setPending(false)
    }
  }

  return (
    <>
      {beenUsed && (
        <PerformanceCalibrationActionPopup
          open={open}
          notification={notification}
          onClose={() => setOpen(false)}
          onResolved={onResolved}
        />
      )}

      <MoreBar.Action
        onClick={() => {
          setOpen(true)
          setBeenUsed(true)
        }}
      >
        View
      </MoreBar.Action>
      <MoreBar.Action onClick={markAsDone} disabled={pending}>
        Mark as done
      </MoreBar.Action>
    </>
  )
}
