import React, { ReactNode, useEffect } from 'react'
import { AxiosPromise } from 'axios'
import { format, isToday } from 'date-fns'

import { Flex, Text, IconButton, FilterButton, Token } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { requestOfReportsEmployeeColumn } from '@src/constants/columns/timeOff'
import { useTable, UseTableOptions } from '@src/components/Table/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import { TableNames } from '@src/constants/table'
import {
  useCurrentMonthRow,
  useCurrentWeekRow,
  useTimeOffCalendarControls,
  useTimeOffCalendarFilters,
} from '@src/features/TimeOffCalendarTable/hooks'
import { ChevronLeft, ChevronRight } from '@revolut/icons'
import { GetRequestData } from '@src/interfaces'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { EmployeeTimeOffRequestsCalendarInterface } from '@src/interfaces/timeOff'
import Table from '@src/components/TableV2/Table'
import Stat from '@src/components/Stat/Stat'
import { TeamInterface } from '@src/interfaces/teams'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

type Props = {
  data: Pick<
    TeamInterface,
    | 'id'
    | 'field_options'
    | 'time_off_request_pending_count'
    | 'time_off_request_upcoming_count'
    | 'time_off_request_in_progress_count'
  >
  weekMonthTab: 'Week' | 'Month'
  getItems: (
    requestData: FetchDataQueryInterface,
  ) => AxiosPromise<GetRequestData<EmployeeTimeOffRequestsCalendarInterface>>
  tableName: TableNames
  tableOptions?: UseTableOptions
  hideFilterButton?: boolean
  hidePolicy?: boolean
  navigation?: ReactNode
  tabBarSwitcher?: ReactNode
  initialFilters?: FilterByInterface[]
}

export const TimeOffCalendar = ({
  data,
  weekMonthTab,
  getItems,
  tableName,
  tableOptions,
  hideFilterButton,
  hidePolicy,
  initialFilters = [],
  navigation,
  tabBarSwitcher,
}: Props) => {
  const {
    currentDay,
    onClickNextWeek,
    onClickPrevWeek,
    onClickNextMonth,
    onClickPrevMonth,
    onClickToday,
  } = useTimeOffCalendarControls()

  const { startOfWeek, endOfWeek, getFilters } = useTimeOffCalendarFilters(
    weekMonthTab === 'Week',
    currentDay,
    initialFilters,
  )

  const table = useTable({ getItems }, getFilters(), undefined, tableOptions)

  useEffect(() => {
    table.onFilterChange(getFilters())
  }, [weekMonthTab, currentDay])

  const canViewPolicyColumn =
    // since we use "team time-off calendar view" on the user's profile page, we can't always rely
    // on the entity-level permission value and sometimes have to pass it externally
    hidePolicy === undefined
      ? data.field_options?.permissions?.includes(
          PermissionTypes.ViewTimeOffRequestPolicy,
        )
      : hidePolicy

  const weekRow = useCurrentWeekRow(
    currentDay,
    [
      {
        ...requestOfReportsEmployeeColumn,
        width: 220,
      },
    ],
    canViewPolicyColumn,
  )

  const monthRow = useCurrentMonthRow(
    currentDay,
    [
      {
        ...requestOfReportsEmployeeColumn,
        width: 220,
      },
    ],
    canViewPolicyColumn,
  )
  return (
    <>
      <Table.Widget.Info>
        <Stat
          label="Pending approval"
          color={Token.color.warning}
          val={data.time_off_request_pending_count}
        />
        <Stat label="In progress" val={data.time_off_request_in_progress_count} />
        <Stat label="Upcoming" val={data.time_off_request_upcoming_count} />
      </Table.Widget.Info>

      <Table.Widget.Filters>
        {navigation}
        {!hideFilterButton && (
          <FilterButton onClick={onClickToday} active={isToday(currentDay)}>
            This {weekMonthTab === 'Week' ? 'week' : 'month'}
          </FilterButton>
        )}
      </Table.Widget.Filters>

      {tabBarSwitcher && <Table.Widget.Actions>{tabBarSwitcher}</Table.Widget.Actions>}
      <Table.Widget.Table>
        <Flex width="100%" justifyContent="space-between" alignItems="center" px="s-16">
          <IconButton
            useIcon={ChevronLeft}
            color={Token.color.greyTone50}
            onClick={weekMonthTab === 'Week' ? onClickPrevWeek : onClickPrevMonth}
            aria-label={weekMonthTab === 'Week' ? 'Previous week' : 'Previous month'}
          />
          {weekMonthTab === 'Week' ? (
            <Text variant="h5">
              {format(startOfWeek, 'dd MMM yyyy')} - {format(endOfWeek, 'dd MMM yyyy')}
            </Text>
          ) : (
            <Text variant="h5">{format(currentDay, 'MMMM yyyy')}</Text>
          )}
          <IconButton
            useIcon={ChevronRight}
            color={Token.color.greyTone50}
            onClick={weekMonthTab === 'Week' ? onClickNextWeek : onClickNextMonth}
            aria-label={weekMonthTab === 'Week' ? 'Next week' : 'Next month'}
          />
        </Flex>
        <AdjustableTable<EmployeeTimeOffRequestsCalendarInterface>
          name={tableName}
          emptyState={<EmptyTableRaw title="No data" />}
          useWindowScroll
          {...table}
          row={weekMonthTab === 'Week' ? weekRow : monthRow}
          enableSettings={false}
          hideCount
          rowHeight="large"
        />
      </Table.Widget.Table>
    </>
  )
}
