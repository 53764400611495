import { Carousel, Popup, Header, Button, useMatchMedia, Token } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { AnnouncementInterface } from '@src/interfaces/announcements'
import { markAnnouncementAsRead, dismissAnnouncement } from '@src/api/announcements'
import { AnnouncementTile } from '@src/pages/Home/HomeSubTabs/Dashboard/components/Announcements/AnnouncementTile'
import { NotificationAction } from '@src/interfaces/notificationTemplate'
import { ANNOUNCEMENT_WIDTH } from './common'
import { EditorView } from '@components/Editor/EditorView'

import { useOpenNewTab } from '@src/actions/RouterActions'

interface AnnouncementProps {
  announcement: AnnouncementInterface
}

export const Announcement = ({ announcement }: AnnouncementProps) => {
  const [isDismissed, setIsDismissed] = useState(
    announcement.status === 'dismissed' || announcement.status === 'read_and_dismissed',
  )
  const [open, setOpen] = useState(false)
  const isMediumScreen = useMatchMedia(Token.media.md)

  const openActionUrl = useOpenNewTab()

  if (isDismissed) {
    return null
  }

  const dismiss = async () => {
    dismissAnnouncement(announcement.id)
    setIsDismissed(true)
  }

  const handleClick = async () => {
    if (announcement.status === 'unread') {
      markAnnouncementAsRead(announcement.id)
    }

    if (announcement.action === NotificationAction.url && announcement.action_url) {
      openActionUrl(announcement.action_url)
      return
    }

    setOpen(true)
  }

  return (
    <>
      <Carousel.Item key={announcement.id} width={ANNOUNCEMENT_WIDTH}>
        <AnnouncementTile
          title={announcement.title}
          color={announcement.color}
          icon={announcement.sender?.avatar || announcement.icon}
          senderName={announcement.sender?.full_name}
          onClick={handleClick}
          date={announcement.creation_date}
          pinned={announcement.pinned}
          dismiss={dismiss}
          backgroundIcon={announcement.background_icon}
          backgroundImage={announcement.background_image}
          description={announcement.subtitle}
          showIcon={announcement.show_icon}
          deadline={announcement.deadline}
        />
      </Carousel.Item>
      <Popup
        open={open}
        onClose={() => setOpen(false)}
        variant={isMediumScreen ? undefined : 'bottom-sheet'}
        size="lg"
      >
        <Header variant="bottom-sheet">
          <Header.Title data-autofocus="true">{announcement.title}</Header.Title>
        </Header>
        <EditorView html={announcement.description} />
        <Popup.Actions horizontal>
          <Button elevated onClick={() => setOpen(false)}>
            Close
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
