import PageLoading from '@components/PageLoading/PageLoading'
import { TabBarWithPinningTabInterface } from '@src/interfaces/tabPinning'
import { Flex, Box } from '@revolut/ui-kit'
import MainHeader from '@src/features/MainHeader/MainHeader'
import { TabBarWithPinning } from '@src/features/TabPinning/TabBarWithPinning'
import { Switch, Route } from 'react-router-dom'
import { PageWithTabs } from '@components/PageWithTabs/PageWithTabs'
import { TableWithSubtabs } from '@src/features/TabPinning/TableWithSubtabs'
import { InternalRedirect } from '@components/InternalLink/InternalRedirect'
import React from 'react'
import { usePermissionChecks } from '@components/PageWithTabs/usePermissionChecks'

interface MainPageInterface {
  sectionName: 'Home' | 'People' | 'Recruitment' | 'Organisation' | 'Performance'
  tabs: TabBarWithPinningTabInterface[]
}

export const MainPage = ({ sectionName, tabs }: MainPageInterface) => {
  const { checkPermissions, isLoading } = usePermissionChecks()

  if (isLoading) {
    return <PageLoading />
  }

  const filteredTabs = tabs.filter(tab => {
    if (tab.isHidden) {
      return false
    }
    if (tab.featureFlag && !checkPermissions(tab)) {
      return false
    }
    return tab.subtabs
      ? tab.subtabs.some(subtab => checkPermissions(subtab))
      : checkPermissions(tab)
  })

  return (
    <Flex pb="s-24" flexDirection="column">
      <MainHeader title={sectionName}>
        <Box mb="s-16">
          <TabBarWithPinning tabs={filteredTabs} />
        </Box>
      </MainHeader>
      <Switch>
        {filteredTabs.map(tab => {
          if (tab.component || tab.subtabs) {
            return (
              <Route
                exact
                key={tab.id}
                path={tab.path}
                render={() => {
                  document.title = `${sectionName} - ${tab.title}`
                  if (tab.component) {
                    return <tab.component />
                  }
                  if (tab.subtabs) {
                    if (tab.isNewNavigation) {
                      return (
                        <PageWithTabs
                          tabs={tab.subtabs}
                          id={tab.id}
                          hideNavigation={tab.hideNavigation}
                        />
                      )
                    }
                    return <TableWithSubtabs subtabs={tab.subtabs} />
                  }
                  return null
                }}
              />
            )
          }
          return null
        })}
        {filteredTabs?.[0]?.path && (
          <InternalRedirect
            to={
              Array.isArray(filteredTabs[0].path)
                ? filteredTabs[0].path[0]
                : filteredTabs[0].path
            }
          />
        )}
      </Switch>
    </Flex>
  )
}
