import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { MoreBar, MoreBarProps } from '@revolut/ui-kit'

const StyledMoreBar = styled(MoreBar)`
  > * {
    direction: ltr;
  }
`

export const TableMoreBar = ({
  children,
  style,
  maxCount,
  ...props
}: PropsWithChildren<MoreBarProps>) => {
  return (
    <StyledMoreBar
      maxCount={maxCount || 3}
      style={{ ...style, direction: 'rtl' }}
      labelMoreButton="More actions"
      {...props}
    >
      {children}
    </StyledMoreBar>
  )
}
