import { useSelector } from 'react-redux'
import React from 'react'
import { BottomNav, Box, SideNav } from '@revolut/ui-kit'
import { matchPath, useLocation } from 'react-router-dom'
import isArray from 'lodash/isArray'

import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { SideBarPinnedApps } from '@src/features/MainLayout/SideBar/SideBarPinnedApps'
import { ROUTES } from '@src/constants/routes'
import { useMainSectionsConfig } from '@src/hooks/useMainSectionsConfig'
import { applicationPathsMap } from '@src/constants/hub'
import { usePinnedApps } from '@src/pages/Hub/Apps/common'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { MainSectionInterface } from '@src/constants/mainSections'

const SideBar = () => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const location = useLocation()
  const mainSectionsConfig = useMainSectionsConfig()
  const { pinnedApps } = usePinnedApps()

  const filteredSideBar = mainSectionsConfig.filter(section => {
    return isArray(section.canView)
      ? section.canView.some(permission => permissions.includes(permission))
      : section.canView
  })

  /** If app is opened and not pinned, we highlight the "Features" sidebar tab as selected */
  /** If app is opened and pinned, we highlight the pinned app sibar tab as selected, and the "Features" tab as not selected */
  const selectedPath = filteredSideBar.find(tab => {
    if (
      tab.name === 'Features' &&
      !!matchPath(location.pathname, { path: ROUTES.APPS.ANY, exact: true })
    ) {
      return !!Object.entries(applicationPathsMap).find(([appId, paths]) => {
        return pinnedApps.includes(appId)
          ? false
          : matchPath(location.pathname, { path: paths })
      })
    }

    return !!matchPath(location.pathname, {
      path: tab.selectedPaths,
      exact: true,
    })
  })

  const isPeopleRoute = selectedPath?.path?.[0] === ROUTES.PEOPLE.ANY
  const employeeProfileRoute = matchPath<{ id: string }>(location.pathname, {
    path: [ROUTES.FORMS.EMPLOYEE.ANY],
    exact: true,
  })
  const isOwnProfileView =
    isPeopleRoute && employeeProfileRoute?.params?.id === String(user.id)

  const isSelected = (sideBarItem: MainSectionInterface) =>
    sideBarItem.name === 'My profile' && isOwnProfileView
      ? true
      : isPeopleRoute
      ? !isOwnProfileView && selectedPath?.name === sideBarItem.name
      : selectedPath?.name === sideBarItem.name

  return (
    <>
      <Box hide="*-md" mb="-s-16" mr="s-12">
        <SideNav collapseOn="xxl" collapsedLabelLocation="under">
          <SideNav.Items>
            {filteredSideBar.map(sideBarItem => (
              <SideNav.Item
                // @ts-expect-error UI kit actually has this
                exact
                key={sideBarItem.name}
                data-testid={sideBarItem.name}
                to={sideBarItem.url || ''}
                use={InternalLink}
                useIcon={sideBarItem.icon}
                aria-selected={isSelected(sideBarItem)}
              >
                {sideBarItem.name}
              </SideNav.Item>
            ))}
          </SideNav.Items>
          <SideBarPinnedApps />
        </SideNav>
      </Box>
      <BottomNav hide="md-*">
        {filteredSideBar.map(sideBarItem => (
          <BottomNav.Item
            key={sideBarItem.name}
            data-testid={sideBarItem.name}
            to={sideBarItem.url || ''}
            use={InternalLink}
            useIcon={sideBarItem.icon}
            aria-label={sideBarItem.name}
            aria-selected={isSelected(sideBarItem)}
          />
        ))}
      </BottomNav>
    </>
  )
}

export default SideBar
