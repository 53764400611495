import React, { useState } from 'react'
import { TopNav } from '@revolut/ui-kit'

import { GlobalSearchSidebarContents } from '@src/components/GlobalSearchSidebar/GlobalSearchSidebar'

export const GlobalSearch = () => {
  const [value, setValue] = useState('')

  return (
    <TopNav.GlobalSearch value={value} onChange={setValue} aria-label="Search" size={24}>
      <GlobalSearchSidebarContents
        value={value}
        onClose={() => TopNav.closePopover()}
        onClear={() => setValue('')}
      />
    </TopNav.GlobalSearch>
  )
}
