import React, { useMemo } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FunctionInterface } from '@src/interfaces/functions'
import {
  specialisationAllSkillsColumn,
  specialisationGenericNameColumn,
  specialisationHeadcountColumn,
  specialisationManagerColumn,
  specialisationRequisitionsColumn,
  specialisationRoleColumn,
  specialisationStatusColumn,
} from '@src/constants/columns/specialisation'
import { nipsColumn } from '@src/constants/columns/department'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings } from '@src/api/settings'
import { specialisationsRequests } from '@src/api/specialisations'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

const row: RowInterface<SpecialisationInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id })),
  cells: [
    {
      ...specialisationGenericNameColumn,
      width: 270,
    },
    {
      ...specialisationRoleColumn,
      width: 250,
    },
    {
      ...specialisationManagerColumn,
      width: 250,
    },
    {
      ...specialisationHeadcountColumn,
      width: 100,
    },
    {
      ...specialisationRequisitionsColumn,
      width: 110,
    },
    {
      ...nipsColumn,
      width: 100,
    },
    {
      ...specialisationStatusColumn,
      width: 200,
    },
    {
      ...specialisationAllSkillsColumn,
      width: 350,
    },
  ],
}

const Specialisations = () => {
  const { values } = useLapeContext<FunctionInterface>()
  const { data: orgSettings } = useGetOrganisationSettings()
  const hiddenCells = useMemo(
    () => ({
      [specialisationStatusColumn.idPoint]:
        !orgSettings?.enable_specialisations_approvals,
    }),
    [orgSettings?.enable_specialisations_approvals],
  )

  const initialFilter = [
    {
      filters: [{ name: values.name, id: values.id }],
      columnName: 'role__function__id',
      nonResettable: true,
    },
    {
      filters: [
        { id: 'pending', name: 'pending' },
        { id: 'approved', name: 'approved' },
        { id: 'draft', name: 'draft' },
      ],
      columnName: 'status',
    },
  ]

  const table = useTable<SpecialisationInterface>(specialisationsRequests, initialFilter)

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {}))
  }

  return (
    <Table.Widget>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <PrimaryAction onClick={handleNewRow} useIcon="Plus">
            Add Specialisation
          </PrimaryAction>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<SpecialisationInterface>
          name={TableNames.FunctionSpecialisations}
          useWindowScroll
          dataType="Specialisation"
          row={row}
          hiddenCells={hiddenCells}
          {...table}
          emptyState={<EmptyTableRaw title="All specialisations will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Specialisations
