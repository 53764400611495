import { MoreBar } from '@revolut/ui-kit'
import React, { PropsWithChildren } from 'react'
import { MoreBarActionProps } from '@revolut/ui-kit/types/dist/components/MoreBar/MoreBar'

export const PrimaryAction = ({
  children,
  ...props
}: PropsWithChildren<MoreBarActionProps>) => {
  return (
    <MoreBar.Action {...props} variant="accent">
      {children}
    </MoreBar.Action>
  )
}
