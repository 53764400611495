import React, { useState } from 'react'
import { useTable } from '@components/TableV2/hooks'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FilterButton, Token } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { OfferTemplateInterface } from '@src/interfaces/offerTemplates'
import { offerTemplatesTableRequests } from '@src/api/offerTemplates'
import { Plus } from '@revolut/icons'
import {
  offerTemplateCreatedByColumn,
  offerTemplateCreatedOnColumn,
  offerTemplateNameColumn,
  offerTemplateStatusColumn,
} from '@src/constants/columns/offerForm/offerTemplates'
import OfferPreviewSidebar from '@components/OfferPreviewSidebar/OfferPreviewSidebar'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const rows: RowInterface<OfferTemplateInterface> = {
  highlight: data => (data.status === 'archived' ? Token.color.greyTone5 : ''),
  cells: [
    {
      ...offerTemplateNameColumn,
      width: 140,
    },
    {
      ...offerTemplateCreatedByColumn,
      width: 300,
    },
    {
      ...offerTemplateCreatedOnColumn,
      width: 160,
    },
    {
      ...offerTemplateStatusColumn,
      width: 160,
    },
  ],
}

export const OfferTemplates = () => {
  const [openTemplateId, setOpenTemplateId] = useState<number>()
  const table = useTable<OfferTemplateInterface>(offerTemplatesTableRequests, [
    {
      filters: [{ name: 'active', id: 'active' }],
      columnName: 'status',
      nonResettable: true,
    },
  ])

  const [showArchived, setShowArchived] = useState(() =>
    table.filterBy.some(
      filter =>
        filter.columnName === 'status' && filter.filters.some(f => f.id === 'archived'),
    ),
  )
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddOfferFormTemplate)

  const onToggleArchived = () => {
    setShowArchived(!showArchived)
    table.onFilterChange([
      {
        filters: showArchived
          ? [{ id: 'active', name: 'active' }]
          : [
              { id: 'active', name: 'active' },
              { id: 'archived', name: 'archived' },
            ],
        columnName: 'status',
      },
    ])
  }

  return (
    <>
      <OfferPreviewSidebar
        templateId={openTemplateId}
        onClose={() => setOpenTemplateId(undefined)}
      />
      <Table.Widget>
        <Table.Widget.Actions>
          {canAdd && (
            <PrimaryAction
              useIcon={Plus}
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.OFFER_TEMPLATE.GENERAL)}
            >
              Create new
            </PrimaryAction>
          )}
        </Table.Widget.Actions>
        <Table.Widget.Filters>
          <Table.Search
            placeholder="Search by name"
            onFilter={table.onFilterChange}
            variant="compact"
          />
          <FilterButton onClick={onToggleArchived} active={showArchived}>
            Archived
          </FilterButton>
        </Table.Widget.Filters>
        <Table.Widget.Table>
          <AdjustableTable<OfferTemplateInterface>
            name={TableNames.OfferTemplates}
            useWindowScroll
            dataType="Offer templates"
            row={rows}
            {...table}
            emptyState={<EmptyTableRaw title="Offer templates will appear here." />}
            onRowClick={data => setOpenTemplateId(data.id)}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}
