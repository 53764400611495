import React from 'react'
import { FormError } from '@src/features/Form/LapeForm'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { useOrdering } from '@components/Table/hooks'
import { TableNames } from '@src/constants/table'
import { noop } from 'lodash'
import { hiringProcessTableRow } from '@src/pages/Forms/SpecialisationForm/HiringProcess/hiringProcessTableRow'

type HiringProcessTableProps = {
  hiringProcessRounds?: HiringProcessInterface[]
  hiringProcessErrors?: (FormError<HiringProcessInterface> | undefined)[]
  loading: boolean
  onDelete?: (data: HiringProcessInterface) => Promise<void>
  onOrderChange?: (data: HiringProcessInterface[]) => void
  onRowClick?: (data: HiringProcessInterface, parentIndexes: number[]) => void
}

export const HiringProcessTable = ({
  hiringProcessRounds,
  hiringProcessErrors,
  loading,
  onDelete,
  onOrderChange,
  onRowClick,
}: HiringProcessTableProps) => {
  const ordering = useOrdering(hiringProcessRounds ?? [], onOrderChange ?? noop)

  return (
    <AdjustableTable<HiringProcessInterface>
      name={TableNames.SpecialisationHiringStages}
      dataType="Hiring stage"
      useWindowScroll
      mainColumnIndex={1}
      row={hiringProcessTableRow(onDelete, onOrderChange)}
      cellErrors={hiringProcessErrors}
      data={hiringProcessRounds ?? []}
      count={hiringProcessRounds?.length || 0}
      orderingMode={!!onOrderChange}
      disabledFiltering
      hideCount
      {...ordering}
      onRowClick={onRowClick}
      noDataMessage="Hiring stages will appear here."
      loading={loading}
    />
  )
}
