import React, { useState } from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { MoreBar } from '@revolut/ui-kit'
import { usersRequests } from '@src/api/accessManagement'
import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'
import {
  actionColumn,
  emailColumn,
  lastLoginColumn,
  statusColumn,
  typeColumn,
  userNameColumn,
} from '@src/constants/columns/accessManagement/accessManagementUsers'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Statuses } from '@src/interfaces'
import { AssignGroupsSidebar } from './AssignGroupsSidebar'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetAccessManagementSettings } from '@src/api/settings'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

interface Props {
  groupId?: number | string
}

const UsersTable = ({ groupId }: Props) => {
  const [selectedUser, setSelectedUser] = useState<UserAccessManagementInterface>()
  const { data: settings } = useGetAccessManagementSettings()

  const initialFilter = [
    {
      columnName: 'status',
      filters: [
        { id: Statuses.active, name: Statuses.active },
        { id: Statuses.inactive, name: Statuses.inactive },
      ],
      nonResettable: true,
    },
    ...(groupId
      ? [
          {
            filters: [{ name: String(groupId), id: groupId }],
            columnName: 'group__id',
            nonResettable: true,
          },
        ]
      : []),
  ]
  const table = useTable<UserAccessManagementInterface>(usersRequests, initialFilter)
  const permissions = useSelector(selectPermissions)

  const canAddServiceAccount =
    permissions.includes(PermissionTypes.AddServiceAccount) &&
    settings?.enable_service_accounts
  const canAssignUserGroups = permissions?.includes(PermissionTypes.AddUserGroups)
  const canInviteEmployees = permissions?.includes(PermissionTypes.CanInviteEmployees)

  const Row: RowInterface<UserAccessManagementInterface> = {
    linkToForm: ({ id }) =>
      navigateTo(pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GENERAL, { id })),
    cells: [
      {
        ...userNameColumn,
        width: 200,
      },
      {
        ...typeColumn,
        width: 200,
      },
      {
        ...emailColumn,
        width: 200,
      },
      {
        ...lastLoginColumn,
        width: 200,
      },
      {
        ...statusColumn,
        width: 200,
      },
      canAssignUserGroups
        ? {
            ...actionColumn(setSelectedUser),
            width: 150,
          }
        : null,
    ].filter(Boolean),
  } as RowInterface<UserAccessManagementInterface>

  return (
    <>
      <Table.Widget>
        <Table.Widget.Filters>
          <Table.Search
            placeholder="Search by name or email"
            onFilter={table.onFilterChange}
            variant="compact"
          />
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {settings?.enable_group_access_request_approvals ? (
              <MoreBar.Action
                useIcon="LockOpened"
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.GROUP_ACCESS_REQUEST.GENERAL)}
              >
                Request access
              </MoreBar.Action>
            ) : null}
            {canAssignUserGroups && (
              <MoreBar.Action
                useIcon="LockOpened"
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.ASSIGN_USER_TO_PERMISSION_GROUPS.GENERAL)}
              >
                Assign groups
              </MoreBar.Action>
            )}
            {canAddServiceAccount && (
              <MoreBar.Action
                useIcon="Plus"
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.ADD_SERVICE_ACCOUNT.GENERAL)}
              >
                Add service account
              </MoreBar.Action>
            )}
            {canInviteEmployees && (
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.INVITE_TEAM.REVIEW)}
                useIcon="AddContact"
              >
                Invite users
              </MoreBar.Action>
            )}
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable<UserAccessManagementInterface>
            name={TableNames.UsersAccess}
            useWindowScroll
            dataType="Users"
            row={Row}
            {...table}
            emptyState={<EmptyTableRaw title="Users will appear here." />}
          />
        </Table.Widget.Table>
      </Table.Widget>

      <AssignGroupsSidebar
        selectedUser={selectedUser}
        onClose={() => setSelectedUser(undefined)}
      />
    </>
  )
}

export default UsersTable
