import React, { useState } from 'react'
import { lowerCase } from 'lodash'
import { Action, Flex, Placeholder } from '@revolut/ui-kit'

import Loader from '@src/components/CommonSC/Loader'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import {
  MAX_ITEMS_ALLOWED_FOR_ANALYSIS,
  ResultsInterface,
  useApplySurveyResultsFilters,
} from './common'
import { useHeatmapTable } from './useHeatmapTable'

export const Heatmap = ({
  itemsToAnalyse,
  surveyId,
  heatmapFilters,
  timelineFilter,
  scopeFilters,
  isLoading,
}: ResultsInterface) => {
  const [validationError, setValidationError] = useState<string | null>()
  const [unknownError, setUnknownError] = useState<string | null>()

  const { row: heatmapRow, table: heatmapTable } = useHeatmapTable({
    surveyId,
    itemsToAnalyse,
    isScopedView: !!scopeFilters,
    onError: e => {
      if (e.response?.status === 400) {
        setValidationError(getMessageFromApiError(e))
      } else {
        setUnknownError(getMessageFromApiError(e))
      }
    },
  })

  useApplySurveyResultsFilters({
    isHeatmap: true,
    disable: isLoading,
    table: heatmapTable,
    timelineFilter,
    heatmapFilters,
    scopeFilters,
    itemsToAnalyse,
    onRefresh: () => {
      setValidationError(undefined)
      setUnknownError(undefined)
    },
  })

  if (isLoading || heatmapTable.loading) {
    return (
      <Flex height={238} alignItems="center" justifyContent="center">
        <Loader />
      </Flex>
    )
  }
  if (unknownError) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
          }}
        />
        <Placeholder.Title>Could not load heatmap data</Placeholder.Title>
        <Placeholder.Description>{unknownError}</Placeholder.Description>
      </Placeholder>
    )
  }
  if (validationError) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D221.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D221@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D221@3x.png',
          }}
        />
        <Placeholder.Title>Filter values need to be adjusted</Placeholder.Title>
        <Placeholder.Description>{validationError}</Placeholder.Description>
        <Placeholder.Actions>
          <Action onClick={heatmapFilters.clearAll}>Clear all filters</Action>
        </Placeholder.Actions>
      </Placeholder>
    )
  }

  const filtersConfigWarning = (() => {
    if (
      heatmapFilters.value &&
      (heatmapFilters.value.length === 0 ||
        heatmapFilters.value.length > MAX_ITEMS_ALLOWED_FOR_ANALYSIS)
    ) {
      return `To see the results you should select up to ${MAX_ITEMS_ALLOWED_FOR_ANALYSIS} ${lowerCase(
        heatmapFilters.groupBy.value.name,
      )} filter options`
    }
    if (timelineFilter.dateFrom || timelineFilter.dateTo) {
      return 'No results for selected dates range'
    }
    return ''
  })()

  return (
    <AdjustableTable
      name={TableNames.EngagementSurveysResultQuestionsHeatmap}
      row={heatmapRow}
      emptyState={
        <EmptyTableRaw
          title={filtersConfigWarning || 'No results found'}
          action={
            filtersConfigWarning ? (
              <Action
                onClick={() => {
                  heatmapFilters.clearAll()
                  timelineFilter.clearAll()
                }}
              >
                Reset all filters
              </Action>
            ) : undefined
          }
        />
      }
      hideCount
      useWindowScroll
      forcedRowHeight="heatmap"
      {...heatmapTable}
    />
  )
}
