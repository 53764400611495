import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { navigateTo } from '@src/actions/RouterActions'
import { getEngagementResultsTableV2Requests } from '@src/api/engagement'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import {
  engagementResultsV2AverageScoreColumn,
  engagementResultsV2QuestionCategoryNameColumn,
  engagementResultsV2QuestionNameColumn,
  engagementResultsV2QuestionTypeColumn,
} from '@src/constants/columns/engagementResultsV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EngagementResultV2Interface } from '@src/interfaces/engagement'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import {
  mainEntrypointBaseRoutes,
  ResultsInterface,
  useApplySurveyResultsFilters,
} from './common'

const getRow = (
  surveyId: number,
  canViewDetails: boolean,
): RowInterface<EngagementResultV2Interface> => ({
  linkToForm: canViewDetails
    ? ({ id: questionId }) =>
        navigateTo(
          pathToUrl(ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.QUESTIONS, {
            id: surveyId,
            questionId,
          }),
        )
    : undefined,
  cells: [
    { ...engagementResultsV2QuestionNameColumn, width: 350 },
    { ...engagementResultsV2QuestionCategoryNameColumn, width: 100 },
    { ...engagementResultsV2QuestionTypeColumn, width: 120 },
    { ...engagementResultsV2AverageScoreColumn, width: 95 },
  ],
})

export const ResultQuestionsTable = ({
  viewMode,
  surveyId,
  isLoading,
  timelineFilter,
  scopeFilters,
}: ResultsInterface) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2DetailsEnabled = featureFlags.includes(
    FeatureFlags.EngagementV2DetailsPages,
  )
  const table = useTable<EngagementResultV2Interface>(
    getEngagementResultsTableV2Requests(surveyId, 'questions'),
    undefined,
    undefined,
    { disable: viewMode !== 'table', disableQuery: true, disableOnEmptyFilters: true },
  )
  useApplySurveyResultsFilters({
    disable: Boolean(isLoading || table.loading || viewMode !== 'table'),
    table,
    timelineFilter,
    scopeFilters,
  })

  const isMainEntryPoint = useRouteMatch(mainEntrypointBaseRoutes.map(route => route.ANY))
  const row = useMemo(
    () => getRow(surveyId, !!isMainEntryPoint && isEngagementV2DetailsEnabled),
    [surveyId, isMainEntryPoint],
  )

  return (
    <>
      <AdjustableTable
        name={TableNames.EngagementSurveysResultQuestions}
        row={row}
        emptyState={<EmptyTableRaw title="Results were not found" />}
        hideCount
        useWindowScroll
        {...table}
      />
    </>
  )
}
