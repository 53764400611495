import React from 'react'
import { useTable } from '@src/components/Table/hooks'
import { RowInterface, Stats } from '@src/interfaces/data'
import { playbooksRequests } from '@src/api/playbooks'
import { PlaybookInterface } from '@src/interfaces/playbooks'
import {
  playbookLastUpdatedColumn,
  playbookRatingColumn,
  playbookTitleColumn,
} from '@src/constants/columns/playbook'
import { Item, Image } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { FunctionInterface } from '@src/interfaces/functions'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

const ROW: RowInterface<PlaybookInterface> = {
  cells: [
    {
      ...playbookTitleColumn,
      width: 740,
    },
    {
      ...playbookLastUpdatedColumn,
      width: 350,
    },
    {
      ...playbookRatingColumn,
      width: 350,
    },
  ],
}

const Playbooks = () => {
  const { values } = useLapeContext<FunctionInterface>()
  const initialFilter = [
    {
      filters: [{ name: values.name, id: values.id }],
      columnName: 'entity_id',
      nonResettable: true,
    },
    {
      filters: [{ name: 'function', id: 'function' }],
      columnName: 'entity_type',
      nonResettable: true,
    },
  ]

  const table = useTable<PlaybookInterface, Stats>(playbooksRequests, initialFilter)

  const openUrl = (rowData: PlaybookInterface) => {
    if (rowData.page_url) {
      window.open(rowData.page_url, '_blank')
    }
  }

  return (
    <Table.Widget>
      <Table.Widget.Status>
        <Item mt="s-16">
          <Item.Avatar>
            <Image src="https://assets.revolut.com/assets/3d-images-v2/3D018.png" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              Add your Function ID <b>FUNCTION-{values.id}</b> as a label to all
              Confluence pages that your want to display here.
            </Item.Title>
          </Item.Content>
        </Item>
      </Table.Widget.Status>

      <Table.Widget.Table>
        <AdjustableTable<PlaybookInterface>
          name={TableNames.FunctionPlaybooks}
          useWindowScroll
          row={ROW}
          {...table}
          onRowClick={openUrl}
          emptyState={<EmptyTableRaw title="All your playbooks will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Playbooks
