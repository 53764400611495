import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import {
  useTableDispatch,
  ActionType,
} from '@components/TableV2/TableProvider/TableProvider'

export const ColumnsSettingsButton = () => {
  const dispatch = useTableDispatch()

  return (
    <>
      <MoreBar.Separator />
      <MoreBar.Action
        useIcon="Controls"
        onClick={() => dispatch({ type: ActionType.OpenSettings })}
      >
        Column settings
      </MoreBar.Action>
      <MoreBar.Separator />
    </>
  )
}
