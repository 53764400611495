import React from 'react'
import { useSelector } from 'react-redux'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/TableV2/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import { DocumentsCategoriesInterface } from '@src/interfaces/documentsCategories'
import { getDocumentsCategories } from '@src/api/documentsCategories'
import { documentCategoryName } from '@src/constants/columns/documentCategories'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { TableNames } from '@src/constants/table'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const ROW: RowInterface<DocumentsCategoriesInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_CATEGORIES.GENERAL, { id })),
  cells: [
    {
      ...documentCategoryName,
      width: 720,
    },
  ],
}

export const DocumentCategories = () => {
  const table = useTable<DocumentsCategoriesInterface>({
    getItems: getDocumentsCategories,
  })
  const permissions = useSelector(selectPermissions)

  const canCreate = permissions?.includes(PermissionTypes.AddDocumentCategories)

  return (
    <PageWrapper>
      <PageHeader backUrl={ROUTES.SETTINGS.DOCUMENTS} title="Documents Categories" />
      <PageBody maxWidth="100%">
        <Table.Widget>
          <Table.Widget.Actions>
            {canCreate ? (
              <PrimaryAction
                onClick={() =>
                  navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_CATEGORIES.GENERAL))
                }
                useIcon="Plus"
              >
                Create category
              </PrimaryAction>
            ) : null}
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <AdjustableTable
              name={TableNames.DocumentCategories}
              useWindowScroll
              row={ROW}
              {...table}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
    </PageWrapper>
  )
}
