import React from 'react'
import { useSelector } from 'react-redux'
import { FilterButton } from '@revolut/ui-kit'
import { connectionsTableRequests } from '@src/api/dataAnalytics'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  connectionsDatabaseTableColumn,
  connectionsDatabaseUserTableColumn,
  connectionsNameTableColumn,
  connectionsOwnerColumn,
  connectionsServerTableColumn,
  connectionsStatusColumn,
  connectionsTypeTableColumn,
} from '@src/constants/columns/dataAnalytics'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { ConnectionInterface } from '@src/interfaces/dataAnalytics'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const ROW: RowInterface<ConnectionInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.GENERAL, { id })),
  cells: [
    {
      ...connectionsNameTableColumn,
      width: 200,
    },
    {
      ...connectionsOwnerColumn,
      width: 200,
    },
    {
      ...connectionsTypeTableColumn,
      width: 200,
    },
    {
      ...connectionsServerTableColumn,
      width: 200,
    },
    {
      ...connectionsDatabaseTableColumn,
      width: 200,
    },
    {
      ...connectionsDatabaseUserTableColumn,
      width: 200,
    },
    {
      ...connectionsStatusColumn,
      width: 200,
    },
  ],
}

const initialFilterBy = [
  {
    columnName: 'status',
    filters: [{ id: Statuses.active, name: Statuses.active }],
  },
]

export const ConnectionsTable = () => {
  const table = useTable<ConnectionInterface>(connectionsTableRequests, initialFilterBy)
  const featureFlags = useSelector(selectFeatureFlags)
  const canAdd = featureFlags?.includes(FeatureFlags.ReportingConnectionsManagement)

  const isArchived =
    table.filterBy.find(item => item.columnName === 'status')?.filters?.[0]?.id ===
    'archived'

  const onSwitchArchived = () => {
    table.onFilterChange([
      {
        columnName: 'status',
        filters: isArchived
          ? [
              {
                id: Statuses.active,
                name: Statuses.active,
              },
            ]
          : [
              {
                id: Statuses.archived,
                name: Statuses.archived,
              },
            ],
      },
    ])
  }

  return (
    <Table.Widget>
      <Table.Widget.Filters>
        <FilterButton active={isArchived} onClick={onSwitchArchived}>
          Archived connections
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAdd && (
            <PrimaryAction
              to={pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.GENERAL)}
              use={InternalLink}
              useIcon="Plus"
            >
              Add new connection
            </PrimaryAction>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          {...table}
          dataType="Connections"
          hideCount
          name={TableNames.ReportingAppConnections}
          emptyState={<EmptyTableRaw title="List of connections will appear here" />}
          row={ROW}
          useWindowScroll
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
