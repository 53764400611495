import { createMainSectionsConfig } from '@src/constants/mainSections'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

export const useMainSectionsConfig = () => {
  const user = useSelector(selectUser)

  return useMemo(() => createMainSectionsConfig(user?.id), [user?.id])
}
