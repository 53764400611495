import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useParams } from 'react-router-dom'

import { getEmployeeWithManagerFilter } from '@src/api/employees'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { DropdownNavigation } from '@src/components/DropdownNavigation/DropdownNavigation'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { ROUTES } from '@src/constants/routes'
import { useCanViewGrowthPlans } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { EmployeeInterface } from '@src/interfaces/employees'
import { TalentType } from '@src/interfaces/talent/talent'
import { GrowthPlans } from '@src/pages/EmployeeProfile/Preview/Talent/GrowthPlans'
import TalentTab from '@src/pages/Forms/CommonTalentTab/Talent'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { Hiring } from '../ProfileSummary/components/Hiring'

interface Props {
  data: EmployeeInterface
}

export const Talent = ({ data }: Props) => {
  const permissions = useSelector(selectPermissions)
  const params = useParams<{ id: string }>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const canViewGrowthPlans = useCanViewGrowthPlans(data)

  const [functionalManagerPermissions, setFunctionalManagerPermissions] =
    useState<string[]>()

  useEffect(() => {
    getEmployeeWithManagerFilter(data.id, 'quality_control__id').then(res =>
      setFunctionalManagerPermissions(res.data.field_options.permissions),
    )
  }, [])

  const tabs = [
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.DIRECT_REPORTS,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.DIRECT_REPORTS,
        params,
      ),
      title: 'Direct reports',
      component: TalentTab,
      talentType: TalentType.DirectReport,
      canView: true,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.GROWTH_PLANS,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.GROWTH_PLANS,
        params,
      ),
      title: 'Growth Plans',
      component: GrowthPlans,
      canView: canViewGrowthPlans,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.FUNCTIONAL_REPORTS,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.FUNCTIONAL_REPORTS,
        params,
      ),
      title: 'Functional reports',
      component: TalentTab,
      talentType: TalentType.FunctionalReport,
      canView: performanceSettings?.enable_functional_management,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIRING,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIRING, params),
      title: 'Hiring',
      component: Hiring,
      canView: permissions.includes(PermissionTypes.ViewRequisitions),
    },
  ]

  const filteredTabs = tabs.filter(tab => tab.canView)

  return (
    <Switch>
      {filteredTabs.map(tab => (
        <Route exact path={tab.path} key={tab.path}>
          <tab.component
            data={
              tab.talentType === TalentType.FunctionalReport
                ? {
                    ...data,
                    field_options: {
                      ...data.field_options,
                      permissions: functionalManagerPermissions,
                    },
                  }
                : data
            }
            talentType={tab.talentType!}
            navigation={<DropdownNavigation options={filteredTabs} />}
          />
        </Route>
      ))}
      <InternalRedirect to={filteredTabs[0].path} />
    </Switch>
  )
}
