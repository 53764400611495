import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useGetUnreadSystemNotifications } from '@src/api/systemNotifications'
import { NotificationsList } from '@src/features/Notifications/NotificationsList'
import { SystemNotificationInterface } from '@src/interfaces/systemNotifications'
import { setFeedbackOpen } from '@src/store/feedback/actions'

let hasDisplayedNPSPopup = false

const Notifications = () => {
  const dispatch = useDispatch()

  const { data: unread } = useGetUnreadSystemNotifications()

  const [notification, setNotification] = useState<SystemNotificationInterface>()

  useEffect(() => {
    if (!hasDisplayedNPSPopup && !!unread?.has_forced_feedback_notification) {
      hasDisplayedNPSPopup = true
      dispatch(setFeedbackOpen(true))
    }
  }, [unread?.has_forced_feedback_notification])

  return (
    <NotificationsList
      onSetNotification={data => setNotification(data)}
      selectedNotification={notification}
      hasDot={unread?.count ? unread.count > 0 : undefined}
    />
  )
}

export default Notifications
