import { FilterButton, MoreBar } from '@revolut/ui-kit'
import { useTable } from '@components/Table/hooks'
import Stat from '@components/Stat/Stat'
import React from 'react'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  performanceReviewEmployeeColumn,
  performanceReviewReviewerColumn,
  performanceReviewStatusColumn,
} from '@src/constants/columns/performanceReviews'
import {
  PerformanceReviewInterface,
  PerformanceReviewStats,
} from '@src/interfaces/performance'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { ROUTES } from '@src/constants/routes'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import {
  getAllProbationReviewsStats,
  getSubmittableProbationReviews,
  useReviewStats,
} from '@src/api/probationReview'
import {
  probationReviewCheckpointColumn,
  probationReviewGradeColumn,
  probationReviewRoleColumn,
  probationReviewSeniorityColumn,
  probationReviewTeamColumn,
  probationReviewTypeColumn,
} from '@src/constants/columns/probation'
import { calculateCompletion } from '@src/pages/Performance/Reviews/Reviews'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { Statuses } from '@src/interfaces'
import { getReviewsUrl } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/common'
import { useGlobalSettings } from '@src/api/settings'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useRouteMatch } from 'react-router-dom'
import { StatNavigation } from '@src/components/StatNavigation/StatNavigation'
import { reviewStatNavConfig } from './reviewStatNavConfig'

const row = (currentUserId: number): RowInterface<PerformanceReviewInterface> => ({
  linkToForm: data => {
    if (
      (data.status === Statuses.pending || data.status === Statuses.draft) &&
      currentUserId === data.reviewer.id
    ) {
      let path
      switch (data.category) {
        case ReviewCycleCategory.Probation:
          path = ROUTES.FORMS.PROBATION_REVIEW_LAYOUT
          break
        case ReviewCycleCategory.PIP:
        case ReviewCycleCategory.PIP_V2:
          path = ROUTES.FORMS.PIP_REVIEW_LAYOUT
          break
      }

      if (path) {
        return navigateTo(
          pathToUrl(path, {
            employeeId: data.reviewed_employee.id,
            id: data.id,
          }),
        )
      }
      return null
    }

    const checkpointCycleId = data.performance_checkpoint?.cycle_id
      ? `E-${data.performance_checkpoint.cycle_id}`
      : null
    const cycleId = data.performance_checkpoint?.employee_cycle_id
      ? data.performance_checkpoint.employee_cycle_id
      : checkpointCycleId

    if (cycleId === null) {
      return null
    }

    const checkpointNum = data.performance_checkpoint?.number

    if (data.status === Statuses.completed || data.status === Statuses.completed_late) {
      const performanceLink = getReviewsUrl(
        data.category,
        cycleId,
        data.reviewed_employee.id,
        undefined,
      )

      const query = new URLSearchParams(
        checkpointNum
          ? { reviewer_id: String(data.reviewer.id), checkpoint: String(checkpointNum) }
          : { reviewer_id: '' },
      ).toString()

      return navigateTo(`${performanceLink}?${query}`)
    }

    let path
    switch (data.category) {
      case ReviewCycleCategory.Probation:
        path = ROUTES.FORMS.PROBATION_OVERVIEW.CHECKPOINT
        break
      case ReviewCycleCategory.PIP:
      case ReviewCycleCategory.PIP_V2:
        path = ROUTES.FORMS.PIP_OVERVIEW.CHECKPOINT
        break
    }
    if (path) {
      return navigateTo(
        pathToUrl(path, {
          employeeId: data.reviewed_employee.id,
          cycleId,
          id: checkpointNum,
        }),
      )
    }
    return null
  },
  cells: [
    {
      ...performanceReviewEmployeeColumn,
      width: 200,
    },
    {
      ...probationReviewTypeColumn,
      width: 100,
    },
    {
      ...probationReviewCheckpointColumn,
      width: 100,
    },
    { ...probationReviewGradeColumn, width: 150 },
    {
      ...performanceReviewReviewerColumn,
      width: 200,
    },
    {
      ...probationReviewRoleColumn,
      width: 150,
    },
    {
      ...probationReviewSeniorityColumn,
      width: 100,
    },
    {
      ...probationReviewTeamColumn,
      width: 150,
    },
    {
      ...performanceReviewStatusColumn,
      width: 100,
    },
  ],
})

interface ReviewsCommonProps {
  category: 'probation' | 'pip'
}

const ReviewsCommonTable = ({ category }: ReviewsCommonProps) => {
  const permissions = useSelector(selectPermissions)
  const currentUser = useSelector(selectUser)
  const isPerformanceRoute = useRouteMatch(ROUTES.PERFORMANCE.ANY)

  const {
    settings: { probation_enabled, pip_enabled },
  } = useGlobalSettings()
  const canViewCycles = permissions.includes(PermissionTypes.ViewReviewCycles)
  const canViewPipeline =
    canViewCycles && (category === 'probation' ? probation_enabled : pip_enabled)
  const canViewTemplates =
    category === 'probation' &&
    probation_enabled &&
    permissions.includes(PermissionTypes.ViewProbationEmployees)

  const initialFilters = [
    {
      columnName: 'category',
      filters: [{ name: category, id: category }],
      nonResettable: true,
    },
    {
      columnName: 'reviewer__id',
      filters: [{ name: currentUser.display_name, id: currentUser.id }],
      nonResettable: true,
    },
    {
      columnName: 'status',
      filters: [
        { name: Statuses.pending, id: Statuses.pending },
        { name: Statuses.draft, id: Statuses.draft },
        { name: Statuses.completed, id: Statuses.completed },
      ],
      nonResettable: false,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'status',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'reviewed_employee__full_name',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'performance_checkpoint__number',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<PerformanceReviewInterface, PerformanceReviewStats>(
    {
      getItems: getSubmittableProbationReviews,
      getStats: getAllProbationReviewsStats,
    },
    initialFilters,
    initialSortBy,
  )

  const filtersByReviewer =
    table.filterBy.find(filterBy => filterBy.columnName === 'reviewer__id')?.filters || []
  const isFilteredByMyReviews = filtersByReviewer.some(
    filter => filter.id === currentUser.id,
  )

  const toggleFilterByReviews = () => {
    table.onFilterChange(
      isFilteredByMyReviews
        ? [...table.filterBy, { columnName: 'reviewer__id', filters: [] }]
        : [
            ...table.filterBy,
            {
              columnName: 'reviewer__id',
              filters: [{ name: currentUser.display_name, id: currentUser.id }],
            },
          ],
    )
  }

  const displayStats = !isPerformanceRoute
  const statsApi = useReviewStats({ employeeFilters: table.filterBy })

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {isPerformanceRoute && (
          <StatNavigation api={() => statsApi} config={reviewStatNavConfig} />
        )}
        {displayStats && (
          <Stat label="Review completion" val={calculateCompletion(table.stats)} />
        )}
      </Table.Widget.Info>

      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canViewPipeline && (
            <MoreBar.Action
              to={pathToUrl(
                category === 'probation'
                  ? ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_PROBATION
                  : ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_PIP,
              )}
              use={InternalLink}
              useIcon="16/ListBullet"
            >
              View pipeline
            </MoreBar.Action>
          )}
          {canViewTemplates && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_TEMPLATES)}
              use={InternalLink}
              useIcon="DocumentsPair"
            >
              Probation templates
            </MoreBar.Action>
          )}
          <SettingsButton
            path={
              category === 'probation'
                ? ROUTES.PERFORMANCE.SETTINGS.PROBATION
                : ROUTES.PERFORMANCE.SETTINGS.PIP
            }
            canView={settingsConfig.performance.canView}
          />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by name"
          onFilter={table.onFilterChange}
          variant="compact"
        />
        <FilterButton active={isFilteredByMyReviews} onClick={toggleFilterByReviews}>
          My reviews
        </FilterButton>
      </Table.Widget.Filters>

      <Table.Widget.Table>
        <AdjustableTable
          hideCount
          name={TableNames.PerformanceReviews}
          useWindowScroll
          row={row(currentUser.id)}
          {...table}
          count={table.data.length}
          emptyState={<EmptyTableRaw title="All performance reviews will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export const ReviewsCommon = ({ category }: ReviewsCommonProps) => {
  return (
    <OnboardingAppScreen category="reviews">
      <ReviewsCommonTable category={category} />
    </OnboardingAppScreen>
  )
}
