import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { MoreBar } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { GroupAccessManagementInterface } from '@src/interfaces/accessManagement'
import { useTable } from '@components/TableV2/hooks'
import { groupsRequests } from '@src/api/accessManagement'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import {
  certificationExpirationDateColumn,
  descriptionColumn,
  groupNameColumn,
  ownerColumn,
  permissionsCountColumn,
} from '@src/constants/columns/accessManagement/accessManagementGroups'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const Row: RowInterface<GroupAccessManagementInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.DETAILS, { id })),
  cells: [
    {
      ...groupNameColumn,
      width: 300,
    },
    {
      ...descriptionColumn,
      width: 450,
    },
    {
      ...permissionsCountColumn,
      width: 150,
    },
    {
      ...ownerColumn,
      width: 300,
    },
    {
      ...certificationExpirationDateColumn,
      width: 300,
    },
  ],
}

const GroupsTable = () => {
  const permissions = useSelector(selectPermissions)
  const table = useTable<GroupAccessManagementInterface>(
    groupsRequests,
    [
      {
        columnName: 'settings__status',
        filters: [{ id: Statuses.active, name: Statuses.active }],
        nonResettable: true,
      },
    ],
    [
      {
        sortBy: 'settings__certification_expiration_date_time',
        direction: SORT_DIRECTION.DESC,
        nonResettable: true,
      },
    ],
  )

  const canAdd = permissions?.includes(PermissionTypes.AddGroup)

  return (
    <Table.Widget>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by group or description"
          onFilter={table.onFilterChange}
          variant="compact"
        />
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAdd && (
            <MoreBar.Action
              useIcon="Plus"
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.GENERAL)}
            >
              Add group
            </MoreBar.Action>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<GroupAccessManagementInterface>
          name={TableNames.Groups}
          useWindowScroll
          dataType="Groups"
          row={Row}
          {...table}
          emptyState={<EmptyTableRaw title="Groups will appear here." />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default GroupsTable
