import React from 'react'
import { useTable } from '@components/Table/hooks'
import {
  getDepartmentIssues,
  useDepartmentIssuesCounts,
  useDepartmentIssuesCategoryCounts,
} from '@src/api/issues'
import { IssueInterface, IssueType } from '@src/interfaces/issues'
import { IssuesTable } from '@src/features/IssuesTable/IssuesTable'
import { TableNames } from '@src/constants/table'
import { AuditStatuses } from '@src/interfaces/audit'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useParams, Route } from 'react-router-dom'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { useGetOrganisationSettings } from '@src/api/settings'
import { IssuesCircles } from '@src/features/IssuesCircles/IssuesCircles'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import Table from '@src/components/TableV2/Table'

const categoryFilterMap: Record<string, IssueType> = {
  user: 'user_issue',
  product: 'product_audit',
  technical: 'technical_bug',
  issue: 'user',
}

const DepartmentIssuesTable = ({
  data,
  type,
}: {
  data: DepartmentInterface
  type: IssueType
}) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const astraUserIssuesEnabled = featureFlags.includes(
    FeatureFlags.AstraUserIssuesEnabled,
  )
  const initialFilterBy = [
    {
      filters: [
        { id: AuditStatuses.PENDING_FILING, name: AuditStatuses.PENDING_FILING },
        { id: AuditStatuses.QUALITY_REVIEW, name: AuditStatuses.QUALITY_REVIEW },
        { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
        { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
        ...(astraUserIssuesEnabled && type === 'user'
          ? [
              {
                id: AuditStatuses.NOT_APPLICABLE,
                name: AuditStatuses.NOT_APPLICABLE,
              },
              {
                id: AuditStatuses.DONE,
                name: AuditStatuses.DONE,
              },
            ]
          : []),
      ],
      columnName: 'status',
      nonResettable: true,
    },
    {
      filters: [],
      columnName: 'category',
      nonResettable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'severity_sorter',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'due_date',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'average_count',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const table = useTable<IssueInterface>(
    {
      getItems: getDepartmentIssues(data?.id),
    },
    initialFilterBy,
    initialSortBy,
  )

  const { data: counts } = useDepartmentIssuesCounts(data?.id, initialFilterBy)

  const { data: issuesCategoryCounts } = useDepartmentIssuesCategoryCounts(
    data?.id,
    initialFilterBy,
  )

  return (
    <IssuesTable
      table={table}
      name={TableNames.DepartmentIssues}
      counts={counts}
      departmentId={data.id}
      issuesCategoryCounts={issuesCategoryCounts}
      showDepartments={false}
      showIssueType
      showIssueStatus
      showFiltersAsStats
    />
  )
}

export const Issues = ({ data }: { data: DepartmentInterface }) => {
  const params = useParams<{ subtab: string }>()
  const { data: settings } = useGetOrganisationSettings()
  const { data: issuesCategoryCounts } = useDepartmentIssuesCategoryCounts(data?.id)
  const featureFlags = useSelector(selectFeatureFlags)

  const bugTrackingEnabled = !!settings?.bugs_tracking?.enabled
  const findingsEnabled = !!settings?.findings?.enabled
  const userIssuesEnabled = featureFlags?.includes(FeatureFlags.NewUserIssues)

  const type = categoryFilterMap[params.subtab]

  const tabs = [
    {
      title: 'Findings',
      path: ROUTES.FORMS.DEPARTMENT.ISSUES.FINDING,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.ISSUES.FINDING, params),
      quickSummary: <IssuesCircles counts={issuesCategoryCounts?.user_issue} />,
      canView: findingsEnabled,
    },
    {
      title: 'Technical Bugs',
      path: ROUTES.FORMS.DEPARTMENT.ISSUES.TECHNICAL,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.ISSUES.TECHNICAL, params),
      quickSummary: <IssuesCircles counts={issuesCategoryCounts?.technical_bug} />,
      canView: bugTrackingEnabled,
    },
    {
      title: 'User Issues',
      path: ROUTES.FORMS.DEPARTMENT.ISSUES.USER,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.ISSUES.USER, params),
      quickSummary: <IssuesCircles counts={issuesCategoryCounts?.user} />,
      canView: userIssuesEnabled,
    },
    {
      title: 'Product Audits',
      path: ROUTES.FORMS.DEPARTMENT.ISSUES.PRODUCT,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.ISSUES.PRODUCT, params),
      quickSummary: <IssuesCircles counts={issuesCategoryCounts?.product_audit} />,
      canView: bugTrackingEnabled,
    },
  ].filter(({ canView }) => canView)

  return (
    <Table.Widget>
      {tabs.map(tab => (
        <Route exact path={tab.path} key={tab.path}>
          <DepartmentIssuesTable data={data} type={type} />
        </Route>
      ))}
    </Table.Widget>
  )
}
