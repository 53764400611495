import React, { useState, useRef } from 'react'
import { connect } from 'lape'
import {
  Dropdown,
  RadioGroup,
  ColorSelector,
  Grid,
  Box,
  Cell,
  Color,
  Flex,
  Text,
} from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { NotificationTemplateInterface } from '@src/interfaces/notificationTemplate'

const COLORS: Color[] = [
  'deep-purple',
  'blue',
  'light-blue',
  'cyan',
  'green',
  'teal',
  'lime',
  'yellow',
  'red',
  'pink',
  'deep-pink',
  'orange',
  'deep-grey',
  'grey-tone-50',
  'brown',
  'black',
]

const ColorSelect = () => {
  const { values } = useLapeContext<NotificationTemplateInterface>()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleChange = (color: Color | null) => {
    values.announcement_color = color ?? undefined
    setOpen(false)
  }

  return (
    <>
      <Cell use="button" type="button" ref={anchorRef} onClick={() => setOpen(!open)}>
        <Flex alignItems="center">
          <ColorSelector
            aria-label="Deep purple"
            bg={values.announcement_color || 'blue'}
            checked
            size={20}
          />
          <Text pl="s-16">Select tile background color</Text>
        </Flex>
      </Cell>
      <Dropdown open={open} anchorRef={anchorRef} onClose={() => setOpen(false)}>
        <Box bg="grey-20-opaque-10" p="s-8">
          <RadioGroup onChange={handleChange}>
            {group => (
              <Grid gap="s-8" columns={8}>
                {COLORS.map(color => (
                  <ColorSelector
                    bg={color}
                    aria-label={color}
                    size={32}
                    {...group.getInputProps({ value: color })}
                    key={color}
                    checked={color === values.announcement_color}
                  />
                ))}
              </Grid>
            )}
          </RadioGroup>
        </Box>
      </Dropdown>
    </>
  )
}

export default connect(ColorSelect)
