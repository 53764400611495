import React from 'react'
import { Flex } from '@revolut/ui-kit'

import { TeamInterface } from '@src/interfaces/teams'
import { TimeOffCalendar } from '@src/features/TimeOffCalendarTable/TimeOffCalendar'
import {
  useCalendarFilters,
  useTableCalendarSwitcher,
  useWeekMonthSwitcher,
} from '@src/features/TimeOffCalendarTable/hooks'
import { getTeamTimeOffCalendar } from '@src/api/timeOff'
import { TableNames } from '@src/constants/table'
import { TimeOffTable } from './TimeOffTable'
import { TableCalendarTabs } from '@src/features/TimeOffCalendarTable/constants'

import { PermissionTypes } from '@src/store/auth/types'
import Table from '@src/components/TableV2/Table'

enum WeekMonthTabs {
  Week = 'Week',
  Month = 'Month',
}

interface TimeOffProps {
  data: TeamInterface
  navigation?: React.ReactElement
}
export const TeamTimeOff = ({ data, navigation }: TimeOffProps) => {
  const { isMonthView, clearCalendarFilters } = useCalendarFilters()
  const weekMonthSwitcher = useWeekMonthSwitcher(isMonthView)

  const tableCalendarSwitcher = useTableCalendarSwitcher(tab => {
    if (tab === TableCalendarTabs.Table) {
      clearCalendarFilters()
    }
  })

  const canViewApprovals = !!data?.field_options.permissions?.includes(
    PermissionTypes.ViewTimeOff,
  )

  const initialFilters = canViewApprovals
    ? []
    : [
        {
          columnName: 'approval_status',
          filters: [{ id: 'approved', name: 'approved' }],
          nonResettable: true,
        },
      ]

  return (
    <Table.Widget>
      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Table && (
        <TimeOffTable
          data={data}
          initialFilters={initialFilters}
          canViewApprovals={canViewApprovals}
          navigation={navigation}
          tabBarSwitcher={tableCalendarSwitcher.tabBar}
        />
      )}
      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar && (
        <TimeOffCalendar
          tableName={TableNames.TeamTimeOffCalendar}
          data={data}
          navigation={navigation}
          tabBarSwitcher={
            <Flex gap="s-8">
              {weekMonthSwitcher.tabBar}
              {tableCalendarSwitcher.tabBar}
            </Flex>
          }
          weekMonthTab={weekMonthSwitcher.currentTab || WeekMonthTabs.Week}
          getItems={getTeamTimeOffCalendar(data.id)}
          initialFilters={initialFilters}
        />
      )}
    </Table.Widget>
  )
}
