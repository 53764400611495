import React from 'react'
import {
  Flex,
  FilterButton,
  chain,
  HStack,
  Highlights,
  DataPoint,
  DataPointSkeleton,
} from '@revolut/ui-kit'
import { Route, Switch, useLocation, matchPath } from 'react-router-dom'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'
import Table from '@src/components/TableV2/Table'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { DataPointLink } from '@components/StatNavigation/StatNavigation'

interface RiskProps {
  tabs: TabBarTableNavigationInterface[]
  isCompany?: boolean
}

const RiskTab = ({ tabs, isCompany = false }: RiskProps) => {
  const location = useLocation()

  return (
    <Flex flexDirection="column" minHeight={0}>
      <Switch>
        <Table.Widget>
          {isCompany ? (
            <>
              <Table.Widget.Info>
                <CompanyNavigation />
              </Table.Widget.Info>

              <Table.Widget.Filters>
                {tabs.map(tab => (
                  <FilterButton
                    key={String(tab.title)}
                    use={InternalLink}
                    to={String(tab.to)}
                    active={
                      !!matchPath(location.pathname, {
                        path: tab.path,
                        exact: true,
                      })
                    }
                  >
                    <HStack>{chain(tab.title, tab.quickSummary)}</HStack>
                  </FilterButton>
                ))}
              </Table.Widget.Filters>
            </>
          ) : (
            <Table.Widget.Info>
              <Highlights variant="tabs">
                {tabs.map(tab => (
                  <DataPointLink
                    to={String(tab.to)}
                    path={String(tab.path)}
                    key={String(tab.path)}
                  >
                    {tab.quickSummary ? (
                      <DataPoint.Value>{tab.quickSummary}</DataPoint.Value>
                    ) : (
                      <DataPointSkeleton.Value />
                    )}
                    <DataPoint.Label>{tab.title}</DataPoint.Label>
                  </DataPointLink>
                ))}
              </Highlights>
            </Table.Widget.Info>
          )}

          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.url}>
              {tab.component}
            </Route>
          ))}
        </Table.Widget>
      </Switch>
    </Flex>
  )
}

export default RiskTab
