import React from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOrganisationUnitsColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
  analyticsDashboardRelatedGoalsColumn,
} from '@src/constants/columns/analyticsDashboard'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { CompanyInterface } from '@src/interfaces/company'
import { RowInterface } from '@src/interfaces/data'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { useGetCompany } from '@src/api/company'
import Loader from '@components/CommonSC/Loader'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'

interface Props {
  data: CompanyInterface
}

const initialFilterBy = [
  {
    filters: [{ id: 'true', name: 'true' }],
    columnName: 'company_related',
    nonResettable: true,
  },
]

const row: RowInterface<GenericAnalyticsDashboardInterface> = {
  cells: [
    {
      ...analyticsDashboardNameColumn,
      width: 200,
    },
    {
      ...analyticsDashboardOrganisationUnitsColumn,
      width: 100,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: 100,
    },
    {
      ...analyticsDashboardRelatedGoalsColumn,
      width: 100,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 40,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 40,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 80,
    },
  ],
}

export const AnalyticsDashboards = () => {
  const { data, isLoading } = useGetCompany()

  if (isLoading) {
    return <Loader />
  }

  return (
    <AnalyticsDashboardsTab
      data={data}
      entity={EntityTypes.company}
      initialFilter={initialFilterBy}
      row={row}
    />
  )
}

export const CompanyAnalyticsDashboards = () => {
  const { data, isLoading } = useGetCompany()

  if (isLoading) {
    return <Loader />
  }

  return (
    <AnalyticsDashboardsTab
      data={data}
      entity={EntityTypes.company}
      initialFilter={initialFilterBy}
      row={row}
      navigation={<CompanyNavigation />}
    />
  )
}

/** @deprecated */
export const LegacyAnalyticsDashboards = ({ data }: Props) => {
  return (
    <AnalyticsDashboardsTab
      data={data}
      entity={EntityTypes.company}
      initialFilter={initialFilterBy}
      row={row}
    />
  )
}
