import React, { useRef, useState } from 'react'
import { Flex, FilterButton, Ellipsis, Icon, Text } from '@revolut/ui-kit'

import FilterSelect, {
  FilterSelectType,
} from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import { UseFetchResult } from '@src/interfaces'
import { TimeOffRequestYears } from '@src/api/timeOff'
import { getQueries } from '@src/utils/queryParamsHooks'

export const getDefaultYearOption = () => {
  const queryYear = getQueries()?.year
  const year = queryYear || new Date().getFullYear()
  return { id: year, name: `${year}` }
}

interface TimeOffYearSelectProps {
  api: UseFetchResult<{ options: TimeOffRequestYears[] }>
  onChange: (option: OptionInterface) => void
}

export const TimeOffYearSelect = ({ api, onChange }: TimeOffYearSelectProps) => {
  const [selectedYear, setSelectedYear] = useState<OptionInterface>(getDefaultYearOption)
  const [yearSelectOpen, setYearSelectOpen] = useState(false)
  const selectRef = useRef<HTMLButtonElement>(null)

  const yearOptionData = api.data?.options.map(option => ({
    ...option,
    name: `${option.name}`,
  }))

  const yearSelector = async () => ({ options: yearOptionData || [] })

  return (
    <>
      <FilterButton
        useIcon="Calendar"
        aria-haspopup="listbox"
        onClick={() => setYearSelectOpen(!yearSelectOpen)}
        aria-expanded={yearSelectOpen}
        ref={selectRef}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          <Ellipsis>
            <Text variant="emphasis2">{selectedYear.name}</Text>
          </Ellipsis>

          <Icon name={yearSelectOpen ? 'ChevronUp' : 'ChevronDown'} />
        </Flex>
      </FilterButton>
      <FilterSelect
        open={yearSelectOpen}
        selector={yearSelector}
        anchorRef={selectRef}
        onClose={() => setYearSelectOpen(false)}
        value={[selectedYear]}
        onChange={options => {
          const option = options[0]
          if (option) {
            setSelectedYear(option)
            setYearSelectOpen(false)
            onChange(option)
          }
        }}
        type={FilterSelectType.SingleSelect}
        width={100}
      />
    </>
  )
}
