import React, { useEffect } from 'react'
import { Box, Layout, Token } from '@revolut/ui-kit'
import { css } from 'styled-components'

import Sidebar from './SideBar/SideBar'
import { PinnedAppsProvider } from '@src/pages/Hub/Apps/common'
import { PinnedTabsProvider } from '@src/features/TabPinning/pinnedTabs'
import { DemoModeWarningBar } from '@src/features/DemoMode/DemoModeWarningBar'
import { BugReportSide } from '@components/HelpCenter/components/BugReportSide'
import bugReportSideState from '@components/HelpCenter/components/BugReportSideState'
import { Warning } from '@src/features/MainHeader/Warning'
import { useBanner } from '@src/features/UIKitWithThemeProvider/BannerProvider'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'

const withDemoBarCss = css`
  @media ${Token.media.md} {
    top: 30px;
    height: calc(100vh - 30px);
  }
`

const fullWidthVariantCss = css`
  @media ${Token.media.xxl} {
    grid-template-columns: 260px 8px minmax(66rem, 190rem) 0px 24px 16px;
  }
`

interface MainLayoutProps {
  children: React.ReactNode
  fullWidth?: boolean
}

export const MainLayout = ({ children, fullWidth }: MainLayoutProps) => {
  const { visible } = useBanner()
  const appTheme = useAppTheme()

  useEffect(() => {
    appTheme?.setTopNavEnabled(true)

    return () => {
      appTheme?.setTopNavEnabled(false)
    }
  }, [])

  return (
    <Box width="100%">
      <DemoModeWarningBar />
      <PinnedAppsProvider>
        <PinnedTabsProvider>
          <Layout
            variant="container(wide) main(wide) side(wide)"
            css={fullWidth ? fullWidthVariantCss : undefined}
          >
            <Layout.Menu css={visible ? withDemoBarCss : undefined}>
              <Sidebar />
            </Layout.Menu>
            <Layout.Main
              style={{
                isolation: 'isolate',
                paddingTop: 0,
              }}
            >
              {children}
            </Layout.Main>
            <Layout.Side />
            <Warning />
            <BugReportSide
              open={bugReportSideState.open}
              onClose={() => {
                bugReportSideState.open = false
              }}
            />
          </Layout>
        </PinnedTabsProvider>
      </PinnedAppsProvider>
    </Box>
  )
}
